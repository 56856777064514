<!--subscribe start-->
<section class="resume subscribe" id="contact">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 offset-lg-2">
              <div class="title title2">
                  <h3 class="subscribe-head">Need A Experienced Team For Your Project?</h3>
                  <p class="subscribe-sub-head font-primary">Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been</p>
              </div>
          </div>
          <div class="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2">
              <div class="subscribe">
                  <div class="center-content">
                      <div class="form-group">
                          <div class="flex">
                              <input type="email" class="form-control" placeholder="Please Enter Your Email Address" name="email">
                              <div class="button-primary">
                                  <button type="submit" class=" btn btn-default text-white primary-btn">contact me</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!--subscribe end-->
