import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-event-booking',
  templateUrl: './event-booking.component.html',
  styleUrls: ['./event-booking.component.scss']
})
export class EventBookingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
