<section class="parallax-banner">
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 p-0">
                <div class="full-banner" [ngStyle]="{'background-image': 'url(assets/images/ecommerce/full-banner.jpg)'}">
                </div>
            </div>
            <div class="container">
                <div class="banner-contain">
                    <div>
                        <h2>Creative Collection</h2>
                        <p> I must explain to you how all this mistaken idea of denouncing pleasure and praising
                            pain was born and I will give you a complete account of the system.</p>
                        <a class="btn btn-default primary-btn  radius-0 mt-3" href="#">View all</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>