import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import postscribe from 'postscribe';
import { ColorScssService } from '../shared/service/color-scss.service';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {

  constructor(public colorPicker: ColorScssService) { }



  ngOnInit(): void {
    this.colorPicker.setColorScheme('color-2');
    let text = `<script id="bx24_form_inline" data-skip-moving="true">
    (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
            (w[b].forms=w[b].forms||[]).push(arguments[0])};
            if(w[b]['forms']) return;
            var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
            var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
    })(window,document,'https://portal.yokofon.com/bitrix/js/crm/form_loader.js','b24form')

    b24form({"id":"2","lang":"en","sec":"eo7p6t","type":"inline"})
</script>`;
    postscribe('#myDiv', text);
  }

}
