<app-ecommerce-header></app-ecommerce-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Wishlist</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">Wishlist</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>Wishlist</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--section start-->
<section class="wishlist-section section-b-space">
  <div class="container">
    <div class="col-md-12 empty-cart-cls text-center" *ngIf='!wishlistItems.length'>
      <img src="assets/images/empty-wishlist.png" alt="Empty cart" title="Emprt cart">
      <h3 class="mt-4"><b>Wishlist is Empty</b></h3>
      <h4>Explore more shortlist some items.</h4>
    </div>
    <div class="row" *ngIf='wishlistItems.length'>
      <div class="col-sm-12">
        <table class="table cart-table table-responsive-xs">
          <thead>
            <tr class="table-head">
              <th scope="col">image</th>
              <th scope="col">product name</th>
              <th scope="col">price</th>
              <th scope="col">availability</th>
              <th scope="col">action</th>
            </tr>
          </thead>
          <tbody *ngFor="let item of wishlistItems">
            <tr>
              <td>
                <a [routerLink]="['/e-commerce/product', item.id]">
                  <img [src]="item.img" alt="">
                </a>
              </td>
              <td>
                <a [routerLink]="['/e-commerce/product', item.id]">{{ item.name }}</a>
                <div class="mobile-cart-content row">
                  <div class="col-xs-3">
                    <p>in stock</p>
                  </div>
                  <div class="col-xs-3">
                    <h2 class="td-color">{{ item.price | currency:productsService?.currency:'symbol' }}</h2>
                  </div>
                  <div class="col-xs-3">
                    <h2 class="td-color">
                      <a [routerLink]="" (click)="removeItem(item)" class="icon mr-1">
                          <i class="fa fa-times"></i>
                      </a>
                      <a [routerLink]="" (click)="addToCart(item)" class="cart">
                        <i class="ti-shopping-cart"></i>
                      </a>
                    </h2>
                  </div>
                </div>
              </td>
              <td>
                <h2>{{ item.price | currency:productsService?.currency:'symbol' }}</h2>
              </td>
              <td>
                <p>in stock</p>
              </td>
              <td>
                <a [routerLink]="" (click)="removeItem(item)" class="icon mr-3">
                    <i class="fa fa-times"></i>
                </a>
                <a [routerLink]="'/home/cart'" (click)="addToCart(item)" class="cart">
                  <i class="ti-shopping-cart"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row cart-buttons" *ngIf="wishlistItems.length">
        <div class="col-6">
          <a [routerLink]="['/e-commerce']" class="btn btn-default primary-btn radius-0 m-t-10">continue shopping</a>
        </div>
        <div class="col-6">
          <a [routerLink]="['/e-commerce/checkout']" class="btn btn-default primary-btn ra-dius-0 m-t-10">check out</a>
        </div>
      </div>
  </div>
</section>
<!--section end-->
<app-ecommerce-footer></app-ecommerce-footer>

