<!-- subscribe section -->
<section id="contact" class="saas1 subscribe" [ngStyle]="{'background-image': 'url(assets/images/saas1/banner-2.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-7">
                <div class="media">
                    <img class="" src="assets/images/saas1/subscribe-icon.png" alt="profile">
                    <div class="media-body">
                        <h3 class="mt-0 text-white">Start 30 days free trial</h3>
                        <h6 class="text-white">Our users are impatient. They're probably distracted too. Keep it
                            simple and beautiful, fun and functional.
                        </h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="subscribe-input">
                    <form>
                        <input id="useremail" type="email" placeholder="This Field is Required" required>
                        <input id="submit" type="submit" value="Get Started">
                    </form>
                    <div class="text text-center d-flex">
                        <h6 class="text-white">Free 30-day trial</h6><h6 class="text-white">Easy setup</h6><h6
                            class="text-white">Cancel any time</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end subscribe section -->
