<!-- album section Start-->
<section class="music album">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2>album list</h2>
                    </div>
                    <div class="sub-title">
                        <p>Our Latest Album and details Song 2019</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6 d-none d-md-block d-lg-none">
                <div class="album-list overlay-box text-center">
                    <h5 class="text-uppercase list-head">decorner</h5>
                    <div>
                        <h6 class="item-sublist">20 December</h6>
                        <h6 class="item-sublist">Sunday</h6>
                        <h6 class="item-sublist">23:00-0:00</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- album section end-->
