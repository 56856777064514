<!-- sponsor section Start-->
<section class="music sponsor bg-unset" id="sponsor" [ngStyle]="{'background-image': 'url(assets/images/music/sponser/bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2 class="text-uppercase">sponsors</h2>
                    </div>
                    <div class="sub-title">
                        <p>Our new sponsors 2018</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-6" *ngFor="let img of sponsor">
                <div class="sponsor-img">
                    <div class="text-center">
                        <div class="overlay-box">
                            <img [src]="img.img" alt="" class="img-fluid">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- sponsor section end-->
