<app-navbar></app-navbar>
<section class="section yk-bg-primary">
  <div class="shape shape-style-1 shape-primary animated-bg">
    <i></i><i></i><i></i>
  </div>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="div-title-space center-content">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
      <div class="w-100 d-flex justify-content-center">
        <img src="assets/img/body/icono-paquetes.png" alt="" style="
            width: 60px;
            height: 100%;
            margin-top: -45px;
            margin-bottom: -20px;
          " class="img-fluid icon-image" />
      </div>
      <div class="col-lg-6 text-center">
        <h1 class="yk-primary"><i class="ni ni-app"></i></h1>

        <span id="benefitsTitle " class="h2 text-black">
          <span class="monse-sbold"> Beneficios de tus </span>
          <span class="bigj-400"> YOKOPAQ </span>
        </span>
        <h4 class="h4 text-black mt-4 mb-2 monse-sbold">
          Pasa de Gastar a Ganar
        </h4>
        <br />
      </div>
    </div>

    <div class="row row-grid">
      <div class="col-lg-4 text-center">
        <br />
        <div class="card hvr-bounce-in card-lift--hover shadow border-0" style="width: 280px;">
          <div class="card-body py-5">
            <div class="row mb-4">
              <div class="col-lg-12">
                <img src="./assets/img/body/datos-acumulados.png" width="70" class="image-fluid" />
              </div>

            </div>
            <span class="h5 text-black monse-bold">
              Datos acumulados
            </span>
            <p class="description mt-3 font-weight-bold">
              Acumula tus datos
            </p>
            <br />
            <br />
          </div>
        </div>
      </div>
      <div class="col-lg-4 text-center">
        <br />

        <div class="card hvr-bounce-in card-lift--hover shadow border-0" style="width: 280px;">
          <div class="card-body py-5">
            <div class="row mb-4">
              <div class="col-lg-12">
                <img src="./assets/img/body/resociales.png" width="150px" style="margin-bottom: -5px;"
                  class="image-fluid" />
              </div>
            </div>
            <span class="h5 text-black monse-bold" style="margin-top: -10px;">
              Megas Extras
            </span>
            <p class="description mt-3 font-weight-bold" style="margin-top: -10px;">
              Goza de las redes que te trae tu <span class="bigj-400">YOKOPAQ</span>
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 text-center">
        <br />

        <div class="card hvr-bounce-in card-lift--hover shadow border-0" style="width: 280px;">
          <div class="card-body py-5">
            <div class="row mb-4">
              <div class="col-lg-12">
                <img src="./assets/img/body/llamadas-ilimitadas.png" width="70px" class="image-fluid" />
              </div>

            </div>
            <span class="h5 text-black monse-bold">
              Llamadas ilimitadas
            </span>
            <p class="description mt-3 font-weight-bold">
              Llama de forma ilimitada a otras compañias y <span class="bigj-400">YOKOFON</span>
            </p>
            <br />

          </div>
        </div>
      </div>
    </div>

  </div>
</section>
<main>
  <div id="topOfTheWorld" class="position-relative">
    <!-- paquetes -->
    <section class="section section-lg section-hero section-shaped" style="background-color: #f1f1f1">
      <!-- Background circles -->
      <div class="w-100 d-flex justify-content-center">
        <img src="assets/img/body/icono-paquetes.png" alt="" style="
            width: 60px;
            height: 100%;
            margin-top: -30px;
            margin-bottom: 20px;
          " class="img-fluid icon-image" />
      </div>
      <div class="shape shape-style-1 shape-primary"></div>
      <div class="">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 text-center">
            <h1 class="yk-secondary"><i class="ni ni-app"></i></h1>
            <div id="yokosTitle" class="h3">
              <span class="bigj-400"> YOKOPAQS </span>
            </div>
            <!-- <h4 class="text-black">
              Con los paquetes de YOKOFON ¡Siempre ganas YOKOS!
            </h4> -->
          </div>
        </div>
      </div>
      <div>
        <div class="app1 screenshot" id="screenshot">
          <div class="container">
            <div class="row p-2">
              <div class="col-2 d-flex justify-content-center" style="height: 100%">
                <button (click)="packagesSlick.slickPrev()" class="btn btn-orange rounded"
                  style="margin-top: calc(140px + 1vw)">
                  <i class="fa fa-chevron-left"></i>
                </button>
              </div>
              <div class="col-8">
                <ngx-slick-carousel class="carousel d-flex w-100 justify-content-center" style="margin-top: 20px"
                  #packagesSlick="slick-carousel" [config]="slideConfig">
                  <div ngxSlickItem *ngFor="let item of packages" class="slide">
                    <div class="hvr-grow fade-in padding-image">
                      <a [routerLink]="['/buy', item.data.ProductID]" style="display: flex; justify-content: center">
                        <img [src]="item.data.WebImage" [alt]="item.data.Name" class="hvr-violet" style="width: 120%" />
                      </a>
                    </div>
                  </div>
                </ngx-slick-carousel>
              </div>
              <div class="col-2 d-flex justify-content-center" style="height: 100%">
                <button id="next-right-btn" (click)="packagesSlick.slickNext()" class="btn btn-orange rounded"
                  style="margin-top: calc(140px + 1vw)">
                  <i class="fa fa-chevron-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- micropaquetes -->
    <section class="section section-hero section-shaped bg-sec" style="background-color: #fff"
      style="height: 50vw; min-height:500px; max-height: 600px;">
      <div class="shape shape-style-1"></div>
      <div>
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 text-center">
            <h1 class="yk-primary"><i class="ni ni-bag-17"></i></h1>
            <h2 id="yokosTitle" class="text-black">
              <span class="bigj-400">MICROPAQS</span>
            </h2>
          </div>
        </div>
        <ngb-tabset [justify]="'center'" class="" type="pills">
          <ngb-tab id="tab31">
            <ng-template ngbTabTitle>
              <div class="btn btn-purple" style="width: 100px !important; margin: 0">
                Datos
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div>
                <div>
                  <div class="app1 screenshot" id="screenshot">
                    <div class="container">
                      <div class="row p-2">
                        <div class="col-2 d-flex justify-content-center" style="height: 100%">
                          <button (click)="microDatosSlick.slickPrev()" class="btn btn-orange rounded"
                            style="margin-top: calc(145px + 1vw)">
                            <i class="fa fa-chevron-left"></i>
                          </button>
                        </div>
                        <div class="col-8">
                          <ngx-slick-carousel class="carousel d-flex w-100 justify-content-center"
                            style="margin-top: 20px" #microDatosSlick="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let item of microDatos" class="slide">
                              <div class="hvr-grow fade-in padding-image">
                                <a [routerLink]="['/buy', item.data.ProductID]"
                                  style="display: flex; justify-content: center">
                                  <img [src]="item.data.WebImage" [alt]="item.data.Name" class="hvr-violet"
                                    style="width: 150%" />
                                </a>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                        </div>
                        <div class="col-2 d-flex justify-content-center" style="height: 100%">
                          <button (click)="microDatosSlick.slickNext()" class="btn btn-orange rounded"
                            style="margin-top: calc(145px + 1vw)">
                            <i class="fa fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="tab32">
            <ng-template ngbTabTitle>
              <div class="btn btn-orange" style="width: 100px !important; margin: 0">
                Minutos
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div>
                <div>
                  <div class="app1 screenshot" id="screenshot">
                    <div class="container">
                      <div class="row p-2">
                        <div class="col-2 d-flex justify-content-center" style="height: 100%">
                          <button (click)="microSaldoSlick.slickPrev()" class="btn btn-orange rounded"
                            style="margin-top: calc(145px + 1vw)">
                            <i class="fa fa-chevron-left"></i>
                          </button>
                        </div>
                        <div class="col-8">
                          <ngx-slick-carousel class="carousel d-flex w-100 justify-content-center"
                            style="margin-top: 20px" #microSaldoSlick="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let item of microSaldo" class="slide">
                              <div class="hvr-grow fade-in padding-image">
                                <a [routerLink]="['/buy', item.data.ProductID]"
                                  style="display: flex; justify-content: center">
                                  <img [src]="item.data.WebImage" [alt]="item.data.Name" class="hvr-violet"
                                    style="width: 150%" />
                                </a>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                        </div>
                        <div class="col-2 d-flex justify-content-center" style="height: 100%">
                          <button (click)="microSaldoSlick.slickNext()" class="btn btn-orange rounded"
                            style="margin-top: calc(145px + 1vw)">
                            <i class="fa fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
          <ngb-tab id="tab33">
            <ng-template ngbTabTitle>
              <div class="btn btn-blue" style="width: 100px !important; margin: 0">
                SMS
              </div>
            </ng-template>
            <ng-template ngbTabContent>
              <div>
                <div>
                  <div class="app1 screenshot" id="screenshot">
                    <div class="container">
                      <div class="row p-2">
                        <div class="col-2 d-flex justify-content-center" style="height: 100%">
                          <button (click)="microSMSSlick.slickPrev()" class="btn btn-orange rounded"
                            style="margin-top: calc(145px + 1vw)">
                            <i class="fa fa-chevron-left"></i>
                          </button>
                        </div>
                        <div class="col-8">
                          <ngx-slick-carousel class="carousel d-flex w-100 justify-content-center"
                            style="margin-top: 20px" #microSMSSlick="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem *ngFor="let item of microSMS" class="slide">
                              <div class="hvr-grow fade-in padding-image">
                                <a [routerLink]="['/buy', item.data.ProductID]"
                                  style="display: flex; justify-content: center">
                                  <img [src]="item.data.WebImage" [alt]="item.data.Name" class="hvr-violet"
                                    style="width: 150%" />
                                </a>
                              </div>
                            </div>
                          </ngx-slick-carousel>
                        </div>
                        <div class="col-2 d-flex justify-content-center" style="height: 100%">
                          <button (click)="microSMSSlick.slickNext()" class="btn btn-orange rounded"
                            style="margin-top: calc(145px + 1vw)">
                            <i class="fa fa-chevron-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-tab>
        </ngb-tabset>
      </div>
    </section>
    <section class="section section-lg section-hero section-shaped" style="background-color: #f1f1f1">
      <div class="shape shape-style-1"></div>
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-lg-6 text-center">
            <h1 class="yk-primary"><i class="ni ni-mobile-button"></i></h1>
            <h2 id="yokosTitle" class="text-black">
              <span class="bigj-400">Recargas</span>
            </h2>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div class="app1 screenshot" id="screenshot">
              <div class="container">
                <div class="row p-2">
                  <div class="col-2 d-flex justify-content-center" style="height: 100%">
                    <button (click)="rechargesSlick.slickPrev()" class="btn btn-orange rounded"
                      style="margin-top: calc(100px + 1vw)">
                      <i class="fa fa-chevron-left"></i>
                    </button>
                  </div>
                  <div class="col-8">
                    <ngx-slick-carousel class="carousel d-flex w-100 justify-content-center" style="margin-top: 20px"
                      #rechargesSlick="slick-carousel" [config]="slideConfig">
                      <div ngxSlickItem *ngFor="let item of recharges" class="slide">
                        <div class="hvr-grow fade-in padding-image-recharges">
                          <a [routerLink]="['/buy', item.data.ProductID]"
                            style="display: flex; justify-content: center;margin-bottom: 20px;">
                            <img [src]="item.data.WebImage" [alt]="item.data.Name" class="hvr-violet"
                              style="width: 80%" />
                          </a>
                        </div>
                      </div>
                    </ngx-slick-carousel>
                  </div>
                  <div class="col-2 d-flex justify-content-center" style="height: 100%">
                    <button (click)="rechargesSlick.slickNext()" class="btn btn-orange rounded"
                      style="margin-top: calc(100px + 1vw)">
                      <i class="fa fa-chevron-right"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>