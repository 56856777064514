<app-navbar></app-navbar>
<!-- carrera -->
<section class="section section-shaped">
  <div class="container">
    <div class="div-beneficios-sm center-content"></div>

    <div
      class="row justify-content-between align-items-center"
      style="margin-top: 20px"
    >
      <br />

      <div class="col-lg-12 text-center mb-5 mb-lg-0">
        <div class="w-100 d-flex justify-content-center">
          <img
            src="assets/img/body/icono-paquetes.png"
            alt=""
            style="width: 60px; height: 100%; margin-bottom: -20px"
            class="img-fluid icon-image"
          />
        </div>
        <br />
        <br />
        <span
          class="h1 text-black font-weight-light monse-sbold"
          id="battleTitle"
        >
          La carrera por las ganancias
        </span>
        <p class="lead text-black mt-4 monse-sbold h2" style="font-weight: 700; margin-bottom: -20px;">
          Sube de nivel completando las misiones y <b>GANA</b> más
          <span class="bigj-400">YOKOS</span> al comprar Paquetes.
        </p>
      </div>
    </div>
  </div>
</section>
<!--team start-->
<section class="app2 team-section p-t-0">
  <div class="container">
    <div class="row text-center">
      <div class="col-md-1"></div>
      <div
        class="col-md-2 col-sm-6 text-center teams hvr-wobble-to-bottom-right"
        *ngFor="let data of users"
      >
        <div class="our-team fade-in">
          <div class="set-relative">
            <div class="border-around overflow-hide m-auto">
              <div class="set-relative overflow-hide">
                <img
                  [src]="data.img"
                  alt=""
                  class="img-fluid"
                  style="width: 100%"
                />
                <div class="overlay-team set-abs"></div>
              </div>
            </div>
          </div>
          <div class="set-relative set-border">
            <div class="set-abs top-0 borders"></div>
          </div>
          <div class="employee">
            <span class="h4 text-center text-black monse-bold"
              ><b>{{ data.name }}</b></span
            >
            <br />
            <span class="h6 text-center">{{ data.designation }}</span>
          </div>
        </div>
      </div>
      <br />
      <div style="width: 100%">
        <a
          href="https://demos.creative-tim.com/argon-design-system-angular/documentation/alerts?ref=adsa-bootstrap-carousel"
          class="btn yf-btn-secondary mt-4"
          style="border-radius: 30px; text-transform: none"
          ><span class="monse-light"> Mira el video de la carrera </span></a
        >
      </div>
    </div>
  </div>
</section>
