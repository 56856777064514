<app-navbar></app-navbar>
<section class="section section-lg section-hero section-shaped yk-primary-white">
  <!-- Background circles -->
  <div class="shape shape-style-1 shape-primary animated-bg" style="margin-top: 2vw">
    <i></i><i></i><i></i>
  </div>
  <div class="container shape-container d-flex align-items-center">
    <div class="col">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-12 col-sm-6 text-center">
          <br />
          <span class="h2 yk-text-black monse-sbold" id="gainsTitle" style="min-width: 200px">
            Beneficios de tus <span class="bigj-400">YOKOS</span>
          </span>
        </div>
        <div class="col-lg-6 text-center">
          <img src="./assets/img/body/Celular-con-yokos.png" style="width: 85%"
            class="img-fluid animate__animated hvr-buzz-out" />
        </div>

        <div class="col-lg-6 text-center" style="margin-top: 2vw">
          <div class="col-lg-12 text-center">

            <br />
            <span class="h5 text-black">
              <span class="monse-sbold"> Los </span>
              <span class="bigj-400"> Yokos </span>
              <span class="monse-sbold"> son tu ganancia </span>
              <br />
            </span>
            <br />
            <ngb-tabset [justify]="'center'" class="mt-5">
              <ngb-tab id="tab31">
                <ng-template ngbTabTitle>
                  <div class="btn btn-green btn-wide">Canjea</div>
                </ng-template>

                <ng-template ngbTabContent>
                  <div class="card-usm shadow shadow-lg--hover" style="
                      height: 280px;
                      width: 400px;
                      max-width: 70vw;
                      display: inline-block;
                      vertical-align: middle;
                    ">
                    <div class="card-body py-5">
                      <div class="icon icon-shape icon-shape-danger rounded-circle mb-4">
                        <img src="./assets/img/body/icono-canjea.png" class="image-fluid" style="width: 75px" />
                      </div>
                      <span class="h5 monse-bold">Canjea</span>
                      <br />
                      <br />

                      <span class="description h6">
                        Canjea tus <span class="bigj-400">YOKOS</span> por
                        <span class="bigj-400">MICROPAQS</span> y mucho más.
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
              <ngb-tab id="tab32">
                <ng-template ngbTabTitle>
                  <div style="width: 100px" class="btn btn-orange btn-wide">
                    Comparte
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="card-usm shadow shadow-lg--hover" style="
                      height: 280px;
                      width: 400px;
                      max-width: 70vw;
                      display: inline-block;
                      vertical-align: middle;
                    ">
                    <div class="card-body py-5">
                      <div class="icon icon-shape rounded-circle mb-4">
                        <img src="./assets/img/body/icono-compartir-1.png" class="image-fluid" style="width: 75px" />
                      </div>
                      <span class="h5 monse-bold">Comparte</span>
                      <br />
                      <br />

                      <span class="description h6">
                        Regala <span class="bigj-400">YOKOS</span> a tu familia
                        y amigos
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
              <ngb-tab id="tab33">
                <ng-template ngbTabTitle>
                  <div style="width: 80px" class="btn btn-blue btn-wide">
                    Ahorra
                  </div>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="card-usm shadow shadow-lg--hover" style="
                      height: 280px;
                      width: 400px;
                      max-width: 70vw;
                      display: inline-block;
                      vertical-align: middle;
                    ">
                    <div class="card-body py-5">
                      <div class="icon icon-shape icon-shape-success rounded-circle mb-4">
                        <img src="./assets/img/body/icono-ahorrar.png" class="image-fluid" style="width: 75px" />
                      </div>
                      <span class="h5 monse-bold">Ahorra</span>
                      <br />
                      <br />

                      <span class="description h6">
                        Invierte menos, al cambiar tus
                        <span class="bigj-400">YOKOS</span> por
                        <span class="bigj-400">MICROPAQS</span> y mucho más.
                      </span>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
  <!-- SVG separator -->
</section>

<section class="section section-lg section-hero section-shaped">
  <!-- Background circles -->
  <div class="shape shape-style-1 shape-primary"></div>
  <div class="container shape-container d-flex align-items-center">
    <div class="col">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 text-center">
          <img src="./assets/img/body/acerca-de-valores-resilencia.png" style="width: 100%" class="img-fluid" />
        </div>

        <div class="col-lg-6 counters" id="menu">
          <h3 class="yk-text-black text-center" id="gainsTitle">
            ¿Cuando ganas <span class="bigj-400">YOKOS</span>?
          </h3>
          <div class="text-center">
            <span class="h5 monse-sbold">Pasa de Gastar a Ganar</span>
          </div>
          <br />
          <div class="">
            <ul class="icon-collection">
              <li class="about-icon">
                <a class="center-content">
                  <div class="row well chip">
                    <img src="assets/img/body/icono-comprar.png" alt="" style="width: 50px; height: 100%"
                      class="img-fluid icon-image" />
                    <h5 class="align-middle">Compras <span class="bigj-400">YOKOPAQS</span></h5>
                  </div>
                </a>
              </li>
            </ul>
            <ul class="icon-collection">
              <li class="about-icon">
                <a class="center-content">
                  <div class="row well chip">
                    <img src="assets/img/body/icono-compartir-1.png" alt="" style="width: 50px; height: 40px"
                      class="img-fluid icon-image" />
                    <h5 style="text-transform: none;text-align: start;" class="align-start">Compartes a familiares
                      y<br /> amigos</h5>

                  </div>
                </a>
              </li>
            </ul>
            <ul class="icon-collection">
              <li class="about-icon">
                <a class="center-content">
                  <div class="row well chip">
                    <img src="assets/img/body/icono-ahorrar.png" alt="" style="width: 50px; height: 40px"
                      class="img-fluid icon-image" />
                    <h5 style="text-transform: none;text-align: start;" class="align-start">Ahorra en la carrera por la
                      <br>ganancia</h5>
                  </div>
                </a>
              </li>
            </ul>
            <br />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SVG separator -->
</section>