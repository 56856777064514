<app-yoga-nav></app-yoga-nav>
<app-yoga-header></app-yoga-header>
<app-yoga-experience></app-yoga-experience>
<app-yoga-schedule></app-yoga-schedule>
<app-yoga-expert></app-yoga-expert>
<app-yoga-testimonial></app-yoga-testimonial>
<app-yoga-pricing></app-yoga-pricing>
<app-yoga-subscribe></app-yoga-subscribe>
<app-yoga-blog></app-yoga-blog>
<app-yoga-event></app-yoga-event>
<app-yoga-brand></app-yoga-brand>
<app-yoga-copyright></app-yoga-copyright>