import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-music-nav',
  templateUrl: './music-nav.component.html',
  styleUrls: ['./music-nav.component.scss']
})
export class MusicNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
