import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wedding-about',
  templateUrl: './wedding-about.component.html',
  styleUrls: ['./wedding-about.component.scss']
})
export class WeddingAboutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
