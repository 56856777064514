<!-- Nav Start-->
<header class="app2 loding-header nav-abs custom-scroll yk-bg-secondary">
  <div class="shape shape-style-1 shape-primary animated-bg"><i></i><i></i><i></i></div>
  <div class="container">
    <div class="row center-content">
      <div class="text-center">
        <div class="col-lg-12">
          <div>
           <img src="assets/img/body/logo-yokofon_blanco.png" alt="" style="width: 350px; max-width: 50vw;" /> 
          </div>
        </div>
      </div>
    </div>

    <div class="child mb-2">
      <app-menu></app-menu>
    </div>
  </div>

</header>
<!-- Nav end-->

<section class="section section-shaped yk-bg-secondary">
  <div class="container shape-container d-flex align-items-center">
    <div class="col px-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 text-center p-2">
          <!-- -->
          <br />

          <span class="h3 yk-text-white yk-text-black monse-sibold" id="chipTitle">
            ¿Quieres emprender <br />con tu celular?
          </span>
          <br />
          <br />

          <span class="h5 monse-reg text-white" style="margin-top: 50px;">
            Sin horarios y sin jefe, actívate <span class="bigj-400">YOKOLIDER</span> e inicia tu propio emprendimiento
            con el "Pasas de Gastar a Ganar"
          </span>
          <br />
          <a
          href="https://yokolideres.web.app/"
          class="btn yf-btn-primary mt-4 p-2"
          style="border-radius: 30px; text-transform: none"
          >
          <span class="monse-reg"> Activate como  </span>
          <span class="bigj-400">YOKOLIDER</span>
        </a>
          <br />
        </div>
        <div class="col-lg-6 text-center">
          <img src="./assets/img/body/chica-yokolider.png" style="width: 100%;" class="hvr-float-shadow" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="section section-lg section-shaped ">
  <div class="shape shape-style-1"></div>
  <div class="container">
    <div class="row justify-content-between align-items-center">
      <div class="col-lg-12 mb-5 mb-lg-0 text-center">
        <h5 class="text-black font-weight-bold" id="chipTitle">
          Te daremos todos los recursos necesarios, para que veas lo fácil que
          puede llegar a ser, tener tu propio emprendimiento.
        </h5>
        <br/>
        <h3 class="text-black font-weight-bold yk-text-black" id="chipTitle">
          “Pasa de Gastar a Ganar”
        </h3>
      </div>
    </div>
  </div>
</section> -->