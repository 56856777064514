<!-- subscribe section Start-->
<section class="wedding subscribe attend-bg bg set-relative" [ngStyle]="{'background-image': 'url(assets/images/wedding-img/backgrounds/attend.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="title">
                    <img src="assets/images/wedding-img/bottom.png" alt=""
                         class="img-fluid title-img">
                    <div class="main-title">
                        <h2 class="gradient-text">are you attending</h2>
                    </div>
                    <div class="sub-title">
                        <p>A beautiful app for consectetur adipisicing elit, sed do eiusmod tempor incididunt ut mollit
                            anim id est laborum. Sedut perspiciatis unde omnis.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row p-b-50 m-b-50">
            <div class="col-lg-6 offset-lg-3">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="d-flex">
                                <input type="email" class="form-control"
                                       placeholder="Please Enter Your Email Address"
                                       name="email">
                                <div class="button-primary">
                                    <button type="submit"
                                            class=" btn btn-default btn-gradient text-white text-capitalize">send me
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</section>
<!-- subscribe section end-->
