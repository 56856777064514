import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-music-footer',
  templateUrl: './music-footer.component.html',
  styleUrls: ['./music-footer.component.scss']
})
export class MusicFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
