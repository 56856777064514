<!-- testimonial section -->
<section class="saas1 testimonial videos testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center">
                <div class="title">
                    <img src="assets/images/saas1/title-logo.png"  alt="title-logo" class="img-fluid">
                    <div class="main-title">
                        <h2>
                            <span class="theme-color">5</span>0k <span class="theme-color">E</span>xperts <span
                                class="theme-color">T</span>rust <span class="theme-color">U</span>s
                        </h2>
                    </div>
                    <hr>
                    <div class="sub-title">
                        <p class="p-padding">Life before Company was very chaotic — we got a lot of phone calls, a lot
                            of mistyped orders. So with Company, the ability to see the order directly from the customer
                            makes it so streamlined.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-12">
                <div class="center-content">
                    <div class="video w-100">
                        <a class="button center-content">
                            <img src="assets/images/saas1/testimonial-video-icon.png" (click)="openVerticallyCentered(content)" alt="video" class="img-fluid">
                            <ng-template #content let-modal>
                                    <div class="modal-body video-modal">
                                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                                            <span aria-hidden="true">&times;</span>
                                          </button>
                                          <iframe class="mfp-iframe" frameborder="0" allowfullscreen="" src="//www.youtube.com/embed/dNIfsv1rKJo?autoplay=1"></iframe>
                                    </div>
                                </ng-template>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="testimonial-slider">
                        <owl-carousel-o [options]="testimonialcarouselOptions" class="testimonial-carousel ">
                                <ng-container *ngFor="let slide of testimonialcarousel">
                                    <ng-template carouselSlide>
                            <div class="testimonial">
                                <div class="testimonial-box">
                                    <img [src]="slide.quote" alt="dot">
                                    <h6>{{slide.review}}</h6>
                                </div>
                            </div>
                            <div class="testi-profile">
                                <div class="media">
                                    <img class="" [src]="slide.userImg"
                                         alt="profile-testimonial">
                                    <div class="media-body">
                                        <h5 class="mt-0">{{slide.userName}}</h5>
                                        <h6>{{slide.position}}</h6>
                                    </div>
                                </div>
                            </div>
                            </ng-template>
                            </ng-container>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
        </div>
</section>
<!-- end testimonial section  -->
