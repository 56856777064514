import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-portfolio',
  templateUrl: './resume-portfolio.component.html',
  styleUrls: ['./resume-portfolio.component.scss']
})
export class ResumePortfolioComponent implements OnInit {


  constructor() {
  }

  ngOnInit() { }

}