<app-navbar></app-navbar>
<!-- header section Start-->
<section class="app1 header" id="home">
  <div class="app1-header bg yk-primary" style="background-color: #F1F1F1;">
    <div class="container-fluid init-section">
      <div class="row">
        <div class="col-xs-12 col-lg-8">

          <div class="card">
            <div class="card-body text-center">
              <h3 class="yk-text-black mb-3">¿Donde puedes recargar tu saldo?</h3>
              <h6> Elige entre las  <b>Ubicaciones</b> fisicas desde el app</h6>
            </div>
            <div class="card-body">
              <agm-map [latitude]="Latitude" [longitude]="Longitude" [zoom]="zoom">
                <agm-marker 
                [latitude]="Latitude" 
                [longitude]="Longitude" 
                [iconUrl]="icon"
                *ngIf="findit">

                </agm-marker>
                <agm-marker *ngFor="let marker of markers; " [latitude]="marker.Latitude"
                  [longitude]="marker.Longitude">
                </agm-marker>
              </agm-map>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-lg-4">

          <div class="card" style="background-color: #8400E2;">
            <div class="card-body text-center">
              <span class="h3 text-white monse-sbold">Contáctate con nuestros <span class="bigj-400">YOKOLIDERES</span> desde el APP</span>
            </div>
            <div class="card-body w-100 d-flex justify-content-center">
              <img src="./assets/img/body/banner-recargas de saldo.png" style="width: 60%;margin-bottom: -20px;" class="img-fluid"  />
            </div>
            
          </div>
        </div>
        <!-- <div class="col-lg-6 col-md-7">
          <div class="center-text">
            <div>
              <div class="header-text centered text">
                <h1>¿Dónde recargar?</h1>
              </div>
              <img
                src="assets/img/body/banner-ubicaciones-recargas.png"
                class="image-fluid div-beneficios-sm heartbeat"
                width="80%"
              />
              <br class="div-beneficios-sm" />
              <br class="div-beneficios-sm" />
              <ngb-tabset [justify]="'center'" class="" type="pills">
                <ngb-tab>
                  <ng-template ngbTabTitle>
                    <div class="chip">
                      &nbsp;&nbsp;Yokolíderes&nbsp;&nbsp;
                    </div>
                  </ng-template>
                  <ng-template ngbTabContent>
                    <div class="card">
                      <div class="card-body">
                        <h4 class="text-black">
                          Contáctate con nuestros Yokolíderes desde la app de
                          Yokofon.
                        </h4>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
                <ngb-tab>
                  <ng-template ngbTabTitle>
                    <div class="chip">
                      Puntos Yokofon
                    </div>
                  </ng-template>
                  <ng-template ngbTabContent>
                    <div class="card">
                      <div class="card-body">
                        <h4 class="text-black">
                          Elige entre 70,000 puntos de venta Yokofon. Así como
                          en las siguientes cadenas:
                          <ul>
                            <li>
                              Cadena 1
                            </li>
                            <li>
                              Cadena 2
                            </li>
                          </ul>
                        </h4>
                      </div>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
        <div class="rotate2 div-beneficios-bg">
          <img
            alt=""
            src="assets/img/body/banner-ubicaciones-recargas.png"
            class="img-fluid"
          />
        </div>
        <div class="squares">
          <img
            alt=""
            src="assets/images/app_landing1/Ellipse.png"
            class="img-fluid img-1 set-abs"
          />
          <img
            alt=""
            src="assets/images/app_landing1/Ellipse.png"
            class="img-fluid img-2 set-abs"
          />
        </div>
        <div class="animation-circle-inverse">
          <i></i>
          <i></i>
          <i></i>
        </div> -->
      </div>
    </div>
  </div>
</section>