<!-- copyright section start-->
<div class="music copyright copyright-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-5 col-sm-12">
                <div class="link-horizontal">
                    <ul>
                        <li>
                            <a href="javascript:void(0)" class="copyright-text op-text">home</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="copyright-text op-text">timeline</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="copyright-text op-text">artist</a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="copyright-text op-text">sponsors </a>
                        </li>
                        <li>
                            <a href="javascript:void(0)" class="copyright-text op-text">contact</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-2 col-sm-12">
                <div class="text-center">
                    <div class="link-horizontal">
                        <ul class="justify-content-center">
                            <li>
                                <a href="javascript:void(0)" class="copyright-text op-text">
                                    <i class="fa fa-facebook" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class="copyright-text op-text">
                                    <i class="fa fa-twitter" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class="copyright-text op-text">
                                    <i class="fa fa-google" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="javascript:void(0)" class="copyright-text op-text">
                                    <i class="fa fa-instagram" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-5 col-sm-12">
                <div class="m-l-auto center-para">
                    <p class="copyright-text text-white text-right op-text">© 2019 unice powered by Pixelstrap</p>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- copyright section end-->
