<app-navbar></app-navbar>
<main>
  <div id="finger"></div>
    <div class="position-relative">
      <!-- intro -->
      <section
        class="section section-lg section-hero section-shaped"
        [hidden]="step1"
      >
        <!-- Background circles -->
        
        
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-12 text-center">
              <h2 id="headerTitle" class="text-black">A unos pasos de tu compra</h2>
              <h4 class="text-black">Ingresa los datos para continuar</h4>
            </div>
          </div>
          
          <br />
          <br />
          <div class="row row-grid justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="">
                <div *ngIf="Product | async as product">
                  <img
                    [src]="product.ProductID.includes('YOKOPAQ') ? assets[product.ProductID] : product.WebImage"
                    [alt]="product.Name"
                    class="image-fluid"
                    style="width: 80%;"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 text-center">
              
              <div class="card   ">
                <div class="card-body px-lg-5 py-lg-5">
                  <div class="text-center mb-4">
                    <h5 class="yk-text-black">Ingresa los datos:</h5>
                  </div>
                  <form
                    [formGroup]="checkoutForm"
                    role="form"
                    (ngSubmit)="validatePhone(checkoutForm.value)"
                  >
                    <div
                      class="form-group mb-3"
                      [ngClass]="{ focused: focus === true }"
                    >
                      <div
                        class="form-group"
                        [ngClass]="{ focused: focus1 === true }"
                      >
                        <label>Número Yokofon: </label>
                        <div class="input-group input-group-alternative">
                          <div class="input-group-prepend">
                            <span class="input-group-text"
                              ><i class="fa fa-mobile"></i
                            ></span>
                          </div>
                          <input
                            id="phone"
                            class="form-control"
                            placeholder="Número"
                            type="text"
                            (focus)="focus1 = true"
                            (blur)="focus1 = false"
                            formControlName="phone"
                          />
                        </div>
                        <img src="../assets/secure-buy.png" width="300px" style="margin-bottom: -50px">

                      </div>
                    </div>
                    <div
                      class="custom-control custom-control-alternative custom-checkbox"
                    >
                      <input
                        class="custom-control-input"
                        id=" customCheckLogin"
                        type="checkbox"
                      />
                    </div>
                    <div class="text-center">
                      <button
                        type="button"
                        class="btn my-4"
                        style="background-color: #FFC72C;"
                        type="submit"
                      >
                        Continuar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="section section-lg section-hero section-shaped"
        [hidden]="step2"
      >
        <!-- Background circles -->
        <div class="shape shape-style-1"></div>
        <div class="container">
          <div class="row align-items-center justify-content-center">
            <div class="col-lg-12 text-center">
              <h2 id="headerTitle" class="text-black">A unos pasos de tu compra</h2>
              <h4 class="text-black">Ingresa los datos para continuar</h4>
            </div>
          </div>
          <br />
          <br />
          <br />
          <div class="row row-grid justify-content-center">
            <div class="col-lg-6 text-center">
              <div class="">
                <div *ngIf="Product | async as product">
                  <img
                    [src]="product.WebImage"
                    [alt]="product.Name"
                    class="image-fluid"
                    style="width: 80%;"
                  />
                </div>
              </div>
            </div>
            
            <div class="col-lg-6 text-center">
              <div class="card  ">
                <div class="card-body px-lg-5 py-lg-5">
                  
                  <form ngNoForm novalidate [formGroup]="myform" (ngSubmit)="ngSubmit($event)" action='https://secureacceptance.cybersource.com/silent/pay' method="POST" role="form" >
                    <div class="form-group mb-3" style="" >
                        <input type="hidden" name="locale" id="locale" value="en"/>
                        <input type="hidden" name="merchantID" id="merchantID" value="{{ merchantID }}" />
                        <input type="hidden" name="payment_method" id="payment_method" value="card" />
                        <!--
                        <input type="hidden" name="bill_to_address_line1" id="bill_to_address_line1" value="Ciudad de Guatemala" />
                        <input type="hidden" name="bill_to_address_city" id="bill_to_address_city" value="Guatemala" />
                        <input type="hidden" name="bill_to_address_postal_code" id="bill_to_address_postal_code" value="01010" />
                        <input type="hidden" name="bill_to_address_state" id="bill_to_address_state" value="GT" />
                        <input type="hidden" name="bill_to_address_country" id="bill_to_address_country" value="GT" />  -->
                        <input type="hidden" name="transaction_type" id="transaction_type" value="sale" />
                        <input type="hidden" name="reference_number" id="reference_number" value="{{referenceNumber}}" />
                        <input type="hidden" name="transaction_uuid" id="transaction_uuid" value="{{uid}}" />
                        <input type="hidden" name="device_fingerprint_id" id="device_fingerprint_id" value="{{session}}" />

                        <input type="hidden" name="access_key" value="{{ accesKey }}" />
                        <input type="hidden" name="profile_id" value="{{ profileId  }}" />
                        <input type="hidden" name="signed_field_names" value="access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,device_fingerprint_id" >
                        <input type="hidden" id="unsigned_field_names" name="unsigned_field_names" value="merchantID,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_postal_code,bill_to_address_state,bill_to_address_country,card_type,card_cvn,card_number,card_expiry_date"/>
                        <input type="hidden" name="signed_date_time" value="{{ transacDate }}">
                        <input type="hidden"  name="signature" value="{{ cripto }}" />


                        <input type="hidden" name="currency" id="currency" value="GTQ" />
                        <input type="hidden" name="card_type" id="card_type" value="{{CardType}}"/>


                        <div class="form-group">
                            <label>Nombre en tarjeta: </label>
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend"> <span class="input-group-text"> <i class="fa fa-user"></i> </span> </div>
                                <input formControlName="bill_to_forename" id="bill_to_forename"
                                   [ngClass]="{'is-valid' : myform.controls.bill_to_forename.valid && (myform.controls.bill_to_forename.dirty || myform.controls.bill_to_forename.touched) ,
                                               'is-invalid' : myform.controls.bill_to_forename.invalid && (myform.controls.bill_to_forename.dirty || myform.controls.bill_to_forename.touched) }"
                            class="form-control" placeholder="Nombre" id="bill_to_forename" type="text" name="bill_to_forename" />
                            </div>
                        </div>

                        <div class="form-group" >
                            <label>Apellido en tarjeta: </label>
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend"> <span class="input-group-text" > <i class="fa fa-user"></i> </span> </div>
                                <input formControlName="bill_to_surname"
                                   [ngClass]="{'is-valid' : myform.controls.bill_to_surname.valid && (myform.controls.bill_to_surname.dirty || myform.controls.bill_to_surname.touched) ,
                                               'is-invalid' : myform.controls.bill_to_surname.invalid && (myform.controls.bill_to_surname.dirty || myform.controls.bill_to_surname.touched) }"
                                id="bill_to_surname" class="form-control" placeholder="Apellido"  name="bill_to_surname"  />
                            </div>
                        </div>

                        <div class="form-group" >
                            <label>Correo Electrónico: </label>
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend"> <span class="input-group-text" > <i class="fa fa-envelope"></i> </span> </div>
                                <input [ngClass]="{'is-valid' : myform.controls.bill_to_email.valid && (myform.controls.bill_to_email.dirty || myform.controls.bill_to_email.touched) ,
                                                   'is-invalid' : myform.controls.bill_to_email.invalid && (myform.controls.bill_to_email.dirty || myform.controls.bill_to_email.touched) }"
                                                                  formControlName="bill_to_email" type="email" id="bill_to_email"
                                class="form-control" placeholder="Correo electrónico"  name="bill_to_email"  />
                            </div>
                        </div>

                    <!-- ubicacion info-->
                    <input type="hidden" name="bill_to_address_line1" id="bill_to_address_line1"  value="1295 Charleston Road" />
                    <input
                      type="hidden" name="bill_to_address_state" id="bill_to_address_state"  value="CA" />
                    <input
                      type="hidden" name="bill_to_address_city"  id="bill_to_address_city"  value="Mountain View" />
                    <input name="bill_to_address_country" value="US" type="hidden"/>
                    <input
                     type="hidden" name="bill_to_address_postal_code" id="bill_to_address_postal_code"  value="90043" />



                    </div>
                    <hr>

                    <!-- credit card info-->
                    <div id="nav-tab-card" class="tab-pane fade show active">
                        <div class="form-group">
                            <label for="cardNumber">Informacion de Tarjeta</label>
                            <div class="input-group">

                                <input onchange="tipo()" formControlName="card_number"
                                       [ngClass]="{'is-valid' : myform.controls.card_number.valid && (myform.controls.card_number.dirty || myform.controls.card_number.touched) ,
                                                   'is-invalid' : myform.controls.card_number.invalid && (myform.controls.card_number.dirty || myform.controls.card_number.touched) }"
                                id="card_number" name="card_number" placeholder="Numero de tarjeta" class="form-control" />

                                <div [ngClass]="{'noType' : firstD === '5', 'normal' : firstD === '0' }" class="input-group-append"> <span class="input-group-text text-muted"> <img class="card-visa" src="../assets/visa.png"> </span> </div>
                                <div [ngClass]="{'noType' : firstD === '4', 'normal' : firstD === '0' }" class="input-group-append"> <span class="input-group-text text-muted"> <img class="card-master" src="../assets/master.png"> </span> </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-8">
                                <div class="form-group">
                                    <label><span class="hidden-xs">Expiracion</span></label>

                                    <input type="hidden" id="card_expiry_date" class="form-control" placeholder="Fecha de vencimiento"
                                      name="card_expiry_date" value="{{format[0]}}-{{format[1]}}" />

                                    <div class="input-group input-group-alternative">
                                    <div class="input-group-append"> <span class="input-group-text text-muted"> <i class="fa fa-credit-card"></i> </span> </div>
                                        <select [ngClass]="{'is-valid': mes }"
                                               id="month" class="form-control" (change)="monthChange($event.target.value)" >
                                            <option *ngFor="let m of month" [ngValue]="month" > {{m.mes}} </option>
                                        </select>
                                        <select  [ngClass]="{'is-valid':  ano }" id="year" class="form-control" (change)="yearChange($event.target.value)">
                                            <option *ngFor="let y of year" [ngValue]="year" > {{y.year}} </option>
                                        </select>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-4">
                                <div class="form-group mb-4">
                                    <label data-toggle="tooltip" title="El código de tres dígitos en el reverso de su tarjeta">CVV
                                        <i class="fa fa-question-circle"></i>
                                    </label>
                                    <div class="input-group input-group-alternative">
                                    <div class="input-group-append"> <span class="input-group-text text-muted"> <i class="fa fa-credit-card"></i> </span> </div>
                                        <input type="text"  id="card_cvn" name="card_cvn" formControlName="card_cvn"
                                           [ngClass]="{'is-valid' : myform.controls.card_cvn.valid && (myform.controls.card_cvn.dirty || myform.controls.card_cvn.touched) ,
                                                       'is-invalid' : myform.controls.card_cvn.invalid && (myform.controls.card_cvn.dirty || myform.controls.card_cvn.touched) }"
                                        class="form-control" placeholder="CVV" >
                                    </div>
                                </div>
                            </div>

                        </div>
                        <hr>
                        
                        <div class="form-group">
                            <label class="d-flex justify-content-end ">Total a pagar:</label>
                            <div class="input-group input-group-alternative">
                                <div  class="input-group-prepend"> <span class="input-group-text" > <i class="fa fa-money-bill-wave"></i> </span> </div>
                                <label class="d-flex justify-content-end form-control"><strong>GTQ {{ paymentAmount  }}</strong></label>
                                <input type="hidden" id="total" class="form-control" name="amount" value="{{ paymentAmount }}" />
                            </div>
                        </div>
                        <hr>
                        <input  type="submit" class="btn my-4"
                        style="background-color: #FFC72C;" value="Confirmar"/>
                        <br/>
                        Este sitio está protegido por reCAPTCHA y por la
                        <a href="https://policies.google.com/privacy">Politica de Privacidad</a> de Google y los
                        <a href="https://policies.google.com/terms">términos de servicio</a>.
                    </div>

                    <div class="custom-control custom-control-alternative custom-checkbox" >
                        <input class="custom-control-input" id=" customCheckLogin2" type="checkbox" />
                    </div>
                </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <ngx-spinner bdColor="rgba(132, 0, 226, 1)" size="big" color="#fff"
      type="square-jelly-box" [fullScreen]="true" ><p style="color: white;">Procesando datos...</p></ngx-spinner>
  </main>
  