import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-music-copyright',
  templateUrl: './music-copyright.component.html',
  styleUrls: ['./music-copyright.component.scss']
})
export class MusicCopyrightComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
