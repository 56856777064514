import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable, Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { FormBuilder, FormGroup, FormControl, ReactiveFormsModule, Validators, FormsModule } from '@angular/forms';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { v1 as uuidv4 } from 'uuid';
import * as CryptoJS from 'crypto-js';
import forge from 'node-forge';
import { OnExecuteData, ReCaptchaV3Service } from 'ng-recaptcha';
import { ColorScssService } from '../shared/service/color-scss.service';
import postscribe from 'postscribe';



@Component({
  selector: 'app-compra',
  templateUrl: './compra.component.html',
  styleUrls: ['./compra.component.scss']
})

export class CompraComponent implements OnInit {
  // variables
  Product;  // almacena el objeto de firebase con los datos del producto
  ProductSnapshot;
  checkoutForm;
  paymentForm;
  focus;
  focus1;
  closeResult: string;
  noYokofon: HTMLElement;
  // new form variables
  preCripto;
  info;
  error;
  uid;
  date;
  pipe = new DatePipe('en-US');
  referenceNumber;
  transacDate;
  preFirma;
  cripto;
  orgId='k8vif92e';
  merchantID;
  accesKey;
  profileId;
  secretKey;
  transAmount;
  product;
  session;
  INFO;
  MERCH;
  Currency;
  myform: FormGroup;
  regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  CardType = '001'
  firstD = '4';
  ano: boolean = false;
  mes: boolean = false;
  format: any[] = [0, 0]
  country = 'GT';
  AC = "GT";
  paymentAmount
  private subscription: Subscription;

  isYokofon = false;
  // bools para manejar los pasos de la compra
  step1;
  step2;
  assets = {
    YOKOPAQ50: "assets/img/body/YOKOPAQ-50.png",
    YOKOPAQ75: "assets/img/body/YOKOPAQ-75.png",
    YOKOPAQ100: "assets/img/body/YOKOPAQ-100.png",
    YOKOPAQ150: "assets/img/body/YOKOPAQ-150.png",
    YOKOPAQ250: "assets/img/body/YOKOPAQ-250.png",
  };
  monthChange(val) {
    this.format[0] = val
    if (!isNaN(val)) {
      this.mes = true
    } else {
      this.mes = false
    }
  }
  tipo() {
    this.firstD = this.myform.value.card_number.slice(0, 1)
  }
  yearChange(val) {
    this.format[1] = val
    if (!isNaN(val)) {
      this.ano = true
    } else {
      this.ano = false
    }
  }
  month: object[] = [
    { "mes": "MM" },
    { "mes": "01" }, { "mes": "02" },
    { "mes": "03" }, { "mes": "04" },
    { "mes": "05" }, { "mes": "06" },
    { "mes": "07" }, { "mes": "08" },
    { "mes": "09" }, { "mes": "10" },
    { "mes": "11" }, { "mes": "12" },
  ]
  year: object[] = [
    { "year": "YYYY" },
    { "year": 2021 }, { "year": 2022 },
    { "year": 2023 }, { "year": 2024 },
    { "year": 2025 }, { "year": 2026 },
  ]

  constructor(private route: ActivatedRoute, private firestore: AngularFirestore, private dataService: DataService,
    private formBuilder: FormBuilder, private spinner: NgxSpinnerService, private recaptchaV3Service: ReCaptchaV3Service,
    public colorPicker: ColorScssService) {
    this.step1 = false;
    this.step2 = true;
    this.checkoutForm = this.formBuilder.group({
      phone: '',
      email: ''
    });
  }

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  public executeImportantAction(): void {
    this.recaptchaV3Service.execute('importantAction')
      .subscribe((token) => {
        console.log("What do I do with this?: ", token)
      });
  }

  ngOnInit() {
    this.colorPicker.setColorScheme('color-2');
    this.route.paramMap.subscribe(params => {
      this.product = params.get('productId');
    });




    this.Product = this.firestore.collection('Products').doc(this.product).valueChanges();
    this.firestore.collection('Products').snapshotChanges().subscribe((productSnapshot) => {
      productSnapshot.forEach((productData: any) => {
        if (productData.payload.doc.data().ProductID === this.product) {
          this.paymentAmount = productData.payload.doc.data().Amount;

        }
      });
    });

    this.myform = new FormGroup({
      bill_to_forename: new FormControl('', [Validators.required, Validators.minLength(4)]),
      bill_to_surname: new FormControl('', [Validators.required, Validators.minLength(4)]),
      bill_to_email: new FormControl('', [Validators.required, Validators.pattern(this.regex)]),
      card_number: new FormControl('', [Validators.pattern("(^4|5|2)[0-9]*"), Validators.required, Validators.minLength(16), Validators.maxLength(18)]),
      card_cvn: new FormControl('', [Validators.pattern("[0-9]*"), Validators.required])
    })

  }

  ngSubmit(e) {
    let valid = this.myform.valid
    this.firstD = this.myform.value.card_number.slice(0, 1)
    this.CardType = parseInt(this.firstD) === 4 ? "001" : "002"
    if (valid && this.ano && this.mes) {
      e.target.submit();
    } else {
      Swal.fire('Atención', "Porfavor completa todos los campos", 'error')
    }
  }

  async preparePayment(phone) {
    this.accesKey = "cfe22cbb093f3a7e8187563f75379d5d";
    this.profileId = "F03CBB23-9D3C-4899-8B36-093950BDECA6";
    this.secretKey = "a8a2fed0fb6f4b598f20411736bc341db0cde4a6dddc417abf0f98a89c74a7a7f4b85449df5f42bca5287fdff0983b2a2805b67a87e147ccbe5b18ea61a30883fff596d0c3414f81a6b7564bd9794c5cdf420ddfbeca4f39a5376de6f9bec1c5bfcc85c60e3d49d3830e5a18a89f2f04c0161a410cdf47adacd681f7ab67d432";
    this.merchantID = "visanetgt_yokofon";
    // genero el uuid de la transaccion
    this.uid = uuidv4();
    this.date = Date.now();
    console.log(this.date)
    this.date = this.date + 21600000;
    console.log(this.date)
    // genero el numero de referencia de la transaccion
    this.referenceNumber = this.pipe.transform(this.date, 'y')
      + this.pipe.transform(this.date, 'MM').toString().padStart(2, '0')
      + this.pipe.transform(this.date, 'dd').toString().padStart(2, '0')
      + this.pipe.transform(this.date, 'HH').toString().padStart(2, '0')
      + this.pipe.transform(this.date, 'mm').toString().padStart(2, '0')
      + this.pipe.transform(this.date, 'ss').toString().padStart(2, '0')
      + Math.floor(Math.random() * 99999).toString().padStart(5, '0')
      this.session = this.referenceNumber;
      let text = `<script type="text/javascript" src="https://h.online-metrix.net/fp/tags.js?org_id=` + this.orgId + `&session_id=` + this.merchantID + this.session + `">
      </script>`;
      postscribe('#finger', text);

    // genero la fecha en el formato de visa
    this.transacDate = this.pipe.transform(this.date, 'y') +
      '-' +
      this.pipe.transform(this.date, 'MM').toString().padStart(2, '0') +
      '-' +
      this.pipe.transform(this.date, 'dd').toString().padStart(2, '0') +
      'T' +
      this.pipe.transform(this.date, 'HH').toString().padStart(2, '0') +
      ':' +
      this.pipe.transform(this.date, 'mm').toString().padStart(2, '0') +
      ':' +
      this.pipe.transform(this.date, 'ss').toString().padStart(2, '0') +
      'Z';

    let transAmount;

    this.firestore.collection('Products').snapshotChanges().subscribe((productSnapshot) => {
      productSnapshot.forEach((productData: any) => {
        if (productData.payload.doc.data().ProductID === this.product) {
          transAmount = productData.payload.doc.data().Amount;
          // creo la firma sin encriptar
          this.preFirma = 'access_key=' + this.accesKey + ',profile_id=' + this.profileId + ',transaction_uuid=' + this.uid + ','
          + 'signed_field_names=access_key,profile_id,transaction_uuid,signed_field_names,unsigned_field_names,signed_date_time,locale,transaction_type,reference_number,amount,currency,device_fingerprint_id,'
          + 'unsigned_field_names=merchantID,payment_method,bill_to_forename,bill_to_surname,bill_to_email,bill_to_address_line1,bill_to_address_city,bill_to_address_postal_code,bill_to_address_state,bill_to_address_country,card_type,card_cvn,card_number,card_expiry_date,'
          + 'signed_date_time=' + this.transacDate + ','
          + 'locale=en,'
          + 'transaction_type=sale,'
          + 'reference_number=' + this.referenceNumber + ','
          + 'amount=' + transAmount + ',currency=GTQ,device_fingerprint_id=' + this.session;

          console.log(this.preFirma)

          // empiezo a encriptar la firma
          var preFirmaList = forge.util.encodeUtf8(this.preFirma)
          var key = forge.util.encodeUtf8(this.secretKey)

          var hmac = forge.hmac.create();
          hmac.start('sha256', key);
          hmac.update(preFirmaList);
          this.preCripto = forge.util.hexToBytes(hmac.digest().toHex())
          this.cripto = btoa(this.preCripto);

        }
      });
    });




    let bodyCard = {
      'TransactionType': 'sale',
      'ReferenceNumber': this.referenceNumber,
      'Amount': this.Product.Amount,
      'Type': this.Product.Type,
      'PaymentMethod': 'card',
      'Mail': 'card',
      'Origin': 'WEB',
      'Phone': phone,
      'ProductID': this.product,
      'apikey': 'sudoaccess'
    }
    this.dataService.sendPostRequest('/USER/App/CardTransaction', bodyCard).subscribe(
      res2 => {
        this.spinner.hide();
        if (res2.status === 0) {
          Swal.fire('Atención', res2.message, 'error')
        } else {

        }
      }
    );
  }
  igu
  validatePhone(customerData) {
    let body = {
      'Phone': customerData.phone,
      'apikey': 'sudoaccess',
    }
    this.spinner.show();
    this.dataService.sendPostRequest('/APPS/Phone/Yokofon', body).subscribe(
      res => {

        if (res.status === 0) {
          Swal.fire('Atención', 'El número ingresado no es un número Yokofon', 'error')
        } else {

          this.recaptchaV3Service.execute('registerSubmit').subscribe(
            (token) => {
              // 'this.user' contains the data of the user we want to create. Add the received token
              let body = {
                'apikey': 'AIzaSyC9Q-U85yW1cFbrN9dNM7ovRPq9aQhmVKY',
                'Token': token,
                'Secret': '6LdKELAZAAAAALxQf9KrLcTi035b--PXpvmyvG6Z'
              }
              this.dataService.sendPostRequest('/BE/CheckCaptcha', body).subscribe(
                res => {
                  console.log("procedo a validar captcha")
                  console.log(res)
                  if (res.status === 1) {
                    this.preparePayment(customerData.phone);
                    this.isYokofon = true;
                    this.step1 = true;
                    this.step2 = false;
                    this.spinner.hide();
                  } else {
                    this.spinner.hide();
                    Swal.fire('Atención', 'No es posible realizar la transacción por problemas de identidad de usuario', 'error')
                  }
                }
              );

            },
            (error) => {
              console.log("error");
            });
        }
      }
    );
  }



  doPayment(paymentData) {
    Swal.fire('Atención', 'Ejecutando pago', 'error');

  }

}
