<!-- footer section start-->
<footer class="music bg-footer" [ngStyle]="{'background-image': 'url(assets/images/music/footer-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading gradient-text">SHOW ADDRESS</h4>
                    <h6 class="contact-sub-text">Exllasa Mall,</h6>
                    <h6 class="contact-sub-text">City mall, marid.</h6>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading gradient-text">CONTACT US</h4>
                    <h6 class="contact-sub-text">+09 56895689</h6>
                    <h6 class="contact-sub-text">+09 56895690</h6>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="contact-details text-center">
                    <h4 class="contact-heading gradient-text">DROP YOUR MAIL</h4>
                    <h6 class="contact-sub-text">demo1@gmail.com</h6>
                    <h6 class="contact-sub-text">demo123@gmail.com</h6>
                </div>
            </div>
        </div>
        <div class="form form-footer p-t-50">
            <div class="row">
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="your name">
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="form-group">
                        <input type="text" placeholder="your email">
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="form-group">
                        <textarea class="form-control" rows="6" placeholder="your message"></textarea>
                    </div>
                </div>
                <div class="booking">
                    <a class="btn btn-default btn-gradient m-0-auto">send</a>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- footer section end-->
<app-tap-to-top></app-tap-to-top>