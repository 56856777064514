<!-- subscribe section start-->
<section class="music subscribe bg-gallery" [ngStyle]="{'background-image': 'url(assets/images/music/gallery/bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2>subscribe</h2>
                    </div>
                    <div class="sub-title">
                        <p>we are wait for you</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="subscribe-title">
                    <h6 class="subscribe-main">Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been</h6>
                    <p class="subscribe-sub">we are wait for you</p>
                </div>
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input type="email" class="form-control text-capitalize"
                                       placeholder="Please Enter Your Email Address"
                                       name="email">
                                <div class="button-primary">
                                    <button type="submit" class=" btn btn-default btn-gradient text-white">send me
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- subscribe section end-->
