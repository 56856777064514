<!--copyright start-->
<div class="agency copyright">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <div class="link-horizontal">
                        <ul>
                            <li>
                                <a class="copyright-text" href="javascript:void(0)">Privacy Policy</a>
                            </li>
                            <li>
                                <a class="copyright-text" href="javascript:void(0)">Terms &amp; Conditions</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div>
                        <h6 class="copyright-text text-white text-right">Copyright ©2019 unice by
                            <i aria-hidden="true" class="fa fa-heart"></i> pixelstrap
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--copyright start-->
    