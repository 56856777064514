<app-navbar></app-navbar>
<!--function start-->
<section id="faq" class="saas1 faq testimonial-bg">
  <div class="div-beneficios-sm center-content">
    <br />
    <br />
  </div>
  <br />
  <br />
  <br />
  <div class="container">
    <div class="row mb-5">
      <div class="col-12" style="margin-top: -50px">
        <div class="text-center">
          <span class="h1 about-font-header text-center text-black monse-sbold">
            Pide tu Chip
          </span>
          <br />
          <br />

          <span class="h4 monse-sbold text-black">

            Tu chip no tiene costo, y se activa comprando el primer <span class="bigj-400"> YOKOPAQ</span>

            <!-- Sube de nivel completando las misiones y GANA más
            <span class="bigj-400"> YOKOS</span> al comprar -->
            <br />
            <br />

          </span>
        </div>
      </div>
      <br />
      <div class="col-lg-6 counters set-order-1 pt-5">
        <div>
          <div>
            <img src="assets/img/body/chico-con-telefono-y-tarjeta-sim-fondo-amarillo.png"
              style="width: 80%; margin-top: -75px" class="mobile-hide rotate div-beneficios-bg" />
            <div class="squares div-beneficios-bg">
              <img alt="" src="assets/images/app_landing1/Ellipse-amarillo.png" class="img-fluid img-1 set-abs" />
              <img alt="" src="assets/images/app_landing1/Ellipse-amarillo.png" class="img-fluid img-2 set-abs" />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 counters set-height">
        <div id="myDiv"></div>
      </div>
      <div class="col-12 w-100 d-flex justify-content-center">
        <a href="https://demos.creative-tim.com/argon-design-system-angular/documentation/alerts?ref=adsa-bootstrap-carousel"
          class="btn yf-btn-secondary mt-4" style="border-radius: 30px; text-transform: none; padding: 12px;"><span
            class="monse-light"> Mira el video de la carrera </span></a>
      </div>
    </div>
  </div>
</section>