<!-- banner section start-->
<section class="wedding banner jenny-bg bg set-relative"  [ngStyle]="{'background-image': 'url(assets/images/wedding-img/backgrounds/jenny-bg.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="text-center">
                    <div class="wedding-banner set-relative">
                        <img src="assets/images/wedding-img/jeny/frem.png" alt="" class="img-fluid frem">
                        <div class="set-abs abs-center">
                            <div class="text-container">
                                <div class="couple-text">
                                    <h2 class="banner-text gradient-text">Jenny</h2>
                                    <h2 class="banner-text gradient-text small">&</h2>
                                    <h2 class="banner-text gradient-text">Jerom</h2>
                                </div>
                                <div>
                                    <h3 class="text-uppercase place">heaven GARDEN</h3>
                                    <h4 class="address">399 Angel Street New York</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- banner section end-->
