<app-navbar></app-navbar>
<!--about start-->
<section class="app2 format yk-primary" id="section">
  <div class="container">
    <div class="div-beneficios-sm center-content">
      <br />
      <div id="header-main">
        <!-- <h2  class="yk-text-black">La nueva telefonia móvil Guatemala
          <span style="font-family: Monserrat-Reg;"></span>
        </h2> -->
      </div>
      <br />
    </div>
    <div class="row">
      <div class="col-lg-6" id="menu">
        <div>
          <div class="center-content animated-bg">
            <i style="margin-top: -10px"></i>
            <i style="margin-top: -10px"></i>
            <i style="margin-top: -10px"></i>
            <br *ngIf="!isMobile" />

            <img
              src="assets/img/body/yoko-giratorio-sin-logo-una-vez-min.gif "
              style="
                width: 50%;
                height: 100%;
                max-width: 100px;
                max-height: 100px;
              "
              class="top"
            />
          </div>
          <br />

          <div class="row">
            <div class="col-xs-6 col-4">
              <a [routerLink]="['/oferta']">
                <img
                  src="assets/paq y recargas violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
            <div class="col-xs-6 col-4">
              <a [routerLink]="['/chip']">
                <img
                  src="assets/pide tu chip violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
            <div class="col-xs-6 col-4">
              <a [routerLink]="['/yokos']">
                <img
                  src="assets/ganar yokos violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-xs-6 col-4">
              <a [routerLink]="['/carrera']">
                <img
                  src="assets/carrera violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
            <div class="col-xs-6 col-4">
              <a [routerLink]="['/puntos']">
                <img
                  src="assets/donde-recargar-violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
            <div class="col-xs-6 col-4">
              <a [routerLink]="['/yokolider']">
                <img
                  src="assets/emprende violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
          </div>
          <div class="row mt-4 2-100">
            <div class="col-xs-6 col-4 offset-4">
              <a [routerLink]="['/faqs']">
                <img
                  src="assets/customer violeta-01.png"
                  style="width: 100%; height: 100%"
                  class="hvr-bounce-in"
                />
              </a>
            </div>
          </div>
          <div
            class="float"
            style="z-index: 20"
            id="arrow"
            [hidden]="this.isHidden"
          >
            <i
              class="fa fa-angle-double-down arrow"
              [scrollTo]="'#video'"
              [scrollDuration]="1000"
            ></i>
          </div>

          <br />
        </div>
      </div>

      <div class="col-lg-6" id="video">
        <br />

        <div class="text-center">
          <div class="header-text div-beneficios-bg">
            <br />
            <br />
            <div class="yk-text-black monse-med" style="font-size: 22px;">La nueva telefonia movil</div>
            <div class="h3 yk-text-black monse-sibold mt-2" style="font-size: 36px;"> Guatemala</div>
          </div>
        </div>
        <br />
        <video width="100%" controls preload="none" frameborder="0">
          <source
            src="assets\video\Expectativa01.mp4"
            type="video/mp4"
            autostart="false"
          />
        </video>
        <!-- <iframe src="" frameborder="0"
          width="100%"
          allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
      </div>
    </div>
  </div>
  <div id="myDiv3"></div>
</section>

<!--about end-->
