
<!--experience section start-->
<section class="yoga format" id="services">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h2 class="">Experience yoga</h2>
                    <img src="assets/images/yoga-img/logo/wave-blue.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-md-7 experience-container">
                <img src="assets/images/yoga-img/exprience.png" class="img-fluid exp-img" alt="">
            </div>
            <div class="col-md-5 counters">
                <div class="center-text">
                    <div class="w-100">
                        <div class="meter m-0">
                            <div class="progress progress1">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">90%</h6>
                                </div>
                            </div>
                        </div>
                        <div class="meter">
                            <div class="progress progress2">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">80%</h6>
                                    <p></p></div>
                            </div>
                        </div>
                        <div class="meter">
                            <div class="progress progress3">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">85%</h6>
                                </div>
                            </div>
                        </div>
                        <div class="meter">
                            <div class="progress progress4">
                                <div>
                                    <h6 class="lorem">lorem ipsum</h6>
                                    <h6 class="percent">70%</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--experience section end-->
