import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';




@Component({
  selector: 'app-custom-redirect',
  templateUrl: './documentacion.component.html',
  styleUrls: ['./documentacion.component.scss']
})

export class DocumentacionComponent implements OnInit {
  // variables
  path = null
  config = null
  constructor(private route: ActivatedRoute, private firestore: AngularFirestore) { }



  ngOnInit() {
    // this.route.paramMap.subscribe(async params => {
    //   const snap = await this.firestore.collection('Config').doc("SMS").collection("Redirects").get().toPromise()
    //   const docs = snap.docs.map(doc => doc.data())
    //   console.log(docs)
    //   const hash = docs.reduce((hash, doc) => ({ ...hash, [doc.path]: doc }), {})
    //   this.path = params.get('path');
    //   const objective = hash[this.path]
      
    //   if (objective?.destiny) {
    //     window.location.href = objective?.destiny
    //   }


    // });
  }







}
