import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecommerce-footer',
  templateUrl: './ecommerce-footer.component.html',
  styleUrls: ['./ecommerce-footer.component.scss']
})
export class EcommerceFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
