<!--speaker section start-->
<section class="event speaker" id="speaker">
    <div class="circle">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2><span>our</span> speaker</h2>
                    </div>
                    <div class="sub-title">
                        <P>Register now and reserve your seat for this <span>year's unice,</span> the
                            largest <span>web development</span> and online marketing <span>conference in UK,</span>
                            covering everything you need to know in order to develop a successful online business.</P>
                    </div>
                </div>
            </div>
            <div class="col-md-3 col-sm-6 speker-container" *ngFor="let data of experts">
                <div class="text-center">
                    <div class="team-img">
                        <img [src]="data.img" alt="" class="img-fluid">
                        <div class="overlay"></div>
                        <div class="social">
                            <ul>
                                <li><a href="#"><i class="fa fa-facebook center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-twitter center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-google center-content" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i class="fa fa-globe center-content" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="employee">
                        <h5 class="e-name text-center">{{data.name}}</h5>
                        <h6 class="post text-center ">{{data.designation}}</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--speaker section end-->
