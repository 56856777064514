<!-- faq section -->
<section id="faq" class="saas1 faq testimonial-bg">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="faq-block">
                    <div>
                        <h3 class="frequent-text">Frequently Asked Questions</h3>
                        <h6>Our users are impatient. They're probably distracted too. Keep it simple and beautiful, fun
                            and
                            functional. Clean aesthetics supported by a strong concept is what we stand for.</h6>
                        <div class="accordion faq" id="accordionExample">
                            <div class="card">
                                    <ngb-accordion [closeOthers]="true" activeIds="static-1">
                                            <ngb-panel id="static-1">
                                                    <ng-template ngbPanelTitle>              
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-2">
                                                    <ng-template ngbPanelTitle>
                                                            
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                       
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                    
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                                       
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-3">
                                                    <ng-template ngbPanelTitle>
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-4">
                                                    <ng-template ngbPanelTitle>
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                            <ngb-panel id="static-5">
                                                    <ng-template ngbPanelTitle>
                                                           <a>
                                                               <div class="fa fa-angle-right rotate"></div>
                                                           </a>
                                                           Do I need to make a payment?
                                                          </ng-template>
                                              <ng-template ngbPanelContent>
                                                            No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is. No one cares about products. People care about ideas. Is a product an idea?
                                                            Noup. Is a brand? A good one is.
                                            </ng-template>
                                            </ngb-panel>
                                        </ngb-accordion>
                            </div>
                         </div>
                        <h6 class="link">Still have a question? Reach out to us: <a>demo@123.com</a></h6>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="faq-img-block">
                    <img src="assets/images/saas1/faq-img.png" class="img-fluid" alt="faq-person">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- end faq section -->
