<!-- Navbar section Start-->
<header class="wedding loding-header nav-abs custom-scroll header-rel">
  <div class="container">
      <div class="row">
          <div class="col-12">
              <nav class="navbar-expand-lg theme-nav w-100">
                <a class="navbar-brand d-none d-lg-block m-0">
                    <img alt="logo" class="logo-abs h-auto" src="assets/images/logo/7.png">
                </a>
                <a class="logo-responsive navbar-brand d-md-block d-lg-none">
                    <img alt="logo" src="assets/images/logo/8.png">
                </a>
                <app-center-menu  class="w-100"></app-center-menu>
              </nav>
          </div>
      </div>
  </div>
</header>
<!-- Navbar section end-->
