import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wedding-footer',
  templateUrl: './wedding-footer.component.html',
  styleUrls: ['./wedding-footer.component.scss']
})
export class WeddingFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
