import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ecommerce-parallax-banner',
  templateUrl: './ecommerce-parallax-banner.component.html',
  styleUrls: ['./ecommerce-parallax-banner.component.scss']
})
export class EcommerceParallaxBannerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
