<!--subscribe section start-->
<section class="event subscribe subscribe-3 set-relative">
    <div class="circle">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2><span>subs</span>cribe</h2>
                    </div>
                    <div class="sub-title">
                        <P>Register now and reserve your seat for this <span>year's unice,</span> the
                            largest <span>web development</span> and online marketing <span>conference in UK,</span>
                            covering everything you need to know in order to develop a successful online business.</P>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
                <div class="subscribe">
                    <div class="center-content">
                        <div class="form-group">
                            <div class="flex">
                                <input type="email" class="form-control text-capitalize"
                                       placeholder="Please Enter Your Email Address"
                                       name="email">
                                <div class="button-primary">
                                    <button type="submit"
                                            class=" btn btn-default primary-btn text-white text-capitalize">
                                        contact me
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--subscribe section end-->
