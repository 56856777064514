<div class="container-fluid main-container">
  <div class="d-flex w-100 justify-content-center">
    <img src="assets/images/logo-yokofon-blanco.png" width="100%" style="filter: invert(100%); max-width: 400px;">
  </div>
  <hr style="border-top: 1px solid rgba(0,0,0,0);">
  <div class="card main-card bg-lider shadow rounded">
    <div class="row">
      <div class="col text-center">
        <h4 class="text-white">
          <b>Documentación Yokolideres</b>
        </h4>
        <small class="text-white">
          Desde aqui puedes descargar todo tipo de documentación.
        </small>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12 col-sm-4 text-center">
      <div class="card main-card shadow rounded card-item">
        <img src="assets/images/chica-yokolider-activate.png" width="50%" style="margin: auto; margin-bottom: 10px" />
        <h4>
          <b>¿Como funciona la Carrera?</b>
        </h4>
        <a href="assets/images/chica-yokolider-activate.png" download="Documentación.png"
          class="btn btn-warning btn-download">
          <i class="fa fa-download"></i>
          Descargar
        </a>
      </div>
    </div>
    <div class="col-xs-12 col-sm-4 text-center">
      <div class="card main-card shadow rounded card-item">
        <img src="assets/images/chico-yokolider-activacion.png" width="50%" style="margin: auto; margin-bottom: 10px" />
        <h4>
          <b>Manual de Yokolider</b>
        </h4>
        <a href="assets/images/chico-yokolider-activacion.png" download="Documentación.png"
          class="btn btn-warning btn-download">
          <i class="fa fa-download"></i>
          Descargar
        </a>
      </div>
    </div>
    <div class="col-xs-12 col-sm-4 text-center">
      <div class="card main-card shadow rounded card-item">
        <img src="assets/images/chicA-yokolider-recargas.png" width="50%" style="margin: auto; margin-bottom: 10px" />
        <h4>
          <b>Trifoliares</b>
        </h4>
        <a href="assets/images/chicA-yokolider-recargas.png" download="Documentación.png"
          class="btn btn-warning btn-download">
          <i class="fa fa-download"></i>
          Descargar
        </a>
      </div>
    </div>
  </div>
</div>