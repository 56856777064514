import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-enterprice-footer',
  templateUrl: './enterprice-footer.component.html',
  styleUrls: ['./enterprice-footer.component.scss']
})
export class enterpriceFooterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
