<section class="wedding countdown-bg bg set-relative"  [ngStyle]="{'background-image': 'url(assets/images/wedding-img/backgrounds/countdown-bg.png)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <img src="assets/images/wedding-img/bottom.png" alt=""
                         class="img-fluid title-img">
                    <div class="main-title">
                        <h2 class="gradient-text">count down</h2>
                    </div>
                    <div class="sub-title">
                        <p>A beautiful app for consectetur adipisicing elit, sed do eiusmod tempor incididunt ut mollit
                            anim id est laborum. Sedut perspiciatis unde omnis.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-8 offset-xl-2">
                <div class="wedding-time">
                    <div class="row">
                        <div class="col-lg-3 offset-lg-0 col-sm-4 offset-sm-2 col-6">
                            <div class="event-content wedding-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class="text-center">
                                            <span id="days" class="days-text gradient-text"></span><span
                                                class="days gradient-text">days</span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 offset-lg-0 col-sm-4 col-6">
                            <div class="event-content wedding-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class=" text-center">
                                            <span id="hours" class="days-text gradient-text"></span><span
                                                class="days gradient-text">hours
                                            </span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 offset-lg-0 col-sm-4 offset-sm-2 col-6">
                            <div class="event-content wedding-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class="text-center">
                                            <span id="minutes" class="days-text gradient-text"></span><span
                                                class="days gradient-text"> minutes</span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 offset-lg-0 col-sm-4 col-6">
                            <div class="event-content wedding-content">
                                <div class="count">
                                    <div class="counter-container">
                                        <h2 class=" text-center">
                                            <span id="seconds" class="days-text gradient-text"></span><span
                                                class="days gradient-text">seconds</span>
                                        </h2>
                                    </div>
                                    <div class="set-border-left"></div>
                                    <div class="set-border-bottom"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="schedule text-center">
                    <h3 class="m-b-15 zexson-text"><i class="fa fa-map-marker m-r-10" aria-hidden="true"></i> Zexson
                        Pro, New York,
                        NY 254</h3>
                    <h4 class="schedule-date"><i class="fa fa-calendar m-r-10" aria-hidden="true"></i>01-04
                        March 2018
                    </h4>
                </div>
            </div>
        </div>
    </div>
</section>
