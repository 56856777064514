<!--copyright css start-->

<div class="app2 bg-black">
  <div class="container">
    <div class="row div-beneficios-bg">
      <div class="col-10">
        <div class="center-content">
          <div class="row">
            <h6 class="copyright-text text-white">Yokofon © 2020</h6>
          </div>

          <div class="row">
            <a href="https://www.facebook.com/Yokofon/"
              ><i
                class="fa fa-facebook text-white social"
                aria-hidden="true"
              ></i
            ></a>

            <a href="https://www.instagram.com/yokofongt/"
              ><i
                class="fa fa-instagram text-white social"
                aria-hidden="true"
              ></i
            ></a>
            <a href="https://twitter.com/yokofon"
              ><i class="fa fa-twitter text-white social" aria-hidden="true"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-2">
        <div class="link-horizontal">
          <ul>
            <li>
              <a class="icon-btn center-content">
                <i
                  class="fa fa-android center-content shadow"
                  aria-hidden="true"
                  style="font-size: 16px; height: 26px; width: 26px"
                ></i>
                <h6 class="text-center text-white">android</h6>
              </a>
            </li>
            <li class="borders-right p-0"></li>
            <li>
              <a class="icon-btn center-content">
                <i
                  class="fa fa-apple center-content shadow"
                  aria-hidden="true"
                  style="font-size: 16px; height: 26px; width: 26px"

                ></i>
                <h6 class="text-center text-white">ios</h6>
              </a>
            </li>
            <li class="borders-right p-0"></li>
            <li>
              <a class="icon-btn center-content" [routerLink]="['/home']">
                <i
                  class="fa fa-home center-content shadow"
                  aria-hidden="true"
                  style="font-size: 16px; height: 26px; width: 26px"

                ></i>
                <h6 class="text-center text-white">Home</h6>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="row div-beneficios-sm">
      <div class="col-12">
        <div class="center-content">
          <div class="row">
            <h6 class="copyright-text text-white">YOKOFON © 2020</h6>
            <br />
          </div>
        </div>
      </div>

      <div class="link-horizontal">
        <ul>
          <li>
            <a class="icon-btn">
              <i
                class="fa fa-android center-content shadow"
                aria-hidden="true"
              ></i>
            </a>
          </li>

          <li>
            <a class="icon-btn">
              <i
                class="fa fa-apple center-content shadow"
                aria-hidden="true"
              ></i>
            </a>
          </li>

          <li>
            <a class="icon-btn" href="https://www.facebook.com/Yokofon/">
              <i
                class="fa fa-facebook center-content shadow"
                aria-hidden="true"
              ></i>
            </a>
          </li>

          <li>
            <a class="icon-btn" href="https://www.instagram.com/yokofongt/">
              <i
                class="fa fa-instagram center-content shadow"
                aria-hidden="true"
              ></i>
            </a>
          </li>

          <li>
            <a class="icon-btn" href="https://twitter.com/yokofon">
              <i
                class="fa fa-twitter center-content shadow"
                aria-hidden="true"
              ></i>
            </a>
          </li>
          <li>
            <a class="icon-btn2" [routerLink]="['/home']">
              <i
                class="fa fa-home center-content shadow"
                aria-hidden="true"
              ></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<!-- copyright css end-->
<!-- <div class="app2 bg-black">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <h6 class="copyright-text text-white">YOKOFON © 2020</h6>
          </div>
          <br />

          <div class="link-horizontal center-content " style="padding-left: 20px;padding-right: 20px;">
            <ul>
              <div class="row">
                <div class="col-2">
                  <li>
                    <a class="icon-btn">
                      <i
                        class="fa fa-android center-content shadow"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </div>
                <div class="col-2">
                  <li>
                    <a class="icon-btn">
                      <i
                        class="fa fa-apple center-content shadow"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </div>
                <div class="col-2">
                  <li>
                    <a
                      class="icon-btn"
                      href="https://www.facebook.com/Yokofon/"
                    >
                      <i
                        class="fa fa-facebook center-content shadow"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </div>
                <div class="col-2">
                  <li>
                    <a
                      class="icon-btn"
                      href="https://www.instagram.com/yokofongt/"
                    >
                      <i
                        class="fa fa-instagram center-content shadow"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </div>
                <div class="col-2">
                  <li>
                    <a class="icon-btn" href="https://twitter.com/yokofon">
                      <i
                        class="fa fa-twitter center-content shadow"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </div>
                <div class="col-2">
                  <li>
                    <a class="icon-btn2" [routerLink]="['/home']">
                      <i
                        class="fa fa-home center-content shadow"
                        aria-hidden="true"
                      ></i>
                    </a>
                  </li>
                </div>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->
