<!-- booking section Start--> 
<section class="music booking bg-gallery" [ngStyle]="{'background-image': 'url(assets/images/music/gallery/bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title4">
                    <div class="main-title">
                        <h2>event booking</h2>
                    </div>
                    <div class="sub-title">
                        <p>we are wait for you</p>
                    </div>
                </div>
            </div>
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="sub-title">
                        <p class="text-white">Register now and reserve your seat for this <span>year's unice,</span> the largest
                        <span>web development</span> and online marketing <span>conference in UK,</span>covering everything you need
                        to know in order to develop a successful online business.</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12">
            <form class="form-inline m-b-50">
                <div class="col">
                    <div class="form-group">
                        <input type="text" placeholder="FIRST NAME">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <input type="text" placeholder="LAST NAME">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <input type="text" placeholder="EMAIL">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <input type="text" placeholder="MOBILE">
                    </div>
                </div>
                <div class="col">
                    <div class="form-group">
                        <input type="text" placeholder="COMPANY NAME">
                    </div>
                </div>
            </form>

            <div class="text-center">
                <a class="btn btn-default btn-gradient m-0-auto">register</a>
            </div>
        </div>
    </div>
</section>
<!-- booking section end-->
