<!-- about section Start-->
<section class="event about bg bg-img bg-about" [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white">About event</h2>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 offset-lg-1 col-md-6">
                <div class="text-right">
                    <div class="announcer-img">
                        <img src="assets/images/event/1.jpg" alt="" class="img-fluid">
                        <img src="assets/images/event/1.jpg" alt="" class="img-fluid">
                        <img src="assets/images/event/l3.jpg" alt="" class="img-fluid" aTilt data-tilt data-tilt-max="3"
                             data-tilt-speed="400" data-tilt-perspective="500">
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-lg-7 col-md-6">
                <div class="abouts center-text">
                    <div class="format">
                        <div class="format-small-text">
                            <h6 class="text-white borders-before text-uppercase"><span>event progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header text-white">Welcome To Business Meeting 2019</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para p-light">Lorem Ipsum is simply dummy text of the printing and
                                typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <p class="about-para p-light">When an unknown printer took a galley of type and scrambled it
                                to make a type specimen book. Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry's</p>
                        </div>
                        <a class="btn btn-default " href="#">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
