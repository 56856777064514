import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.scss']
})
export class AcercaDeComponent implements OnInit {
  teams = [
    { img: "assets/img/about/acerca-de-oportunidad-2.png",
      name: "Oportunidad de emprender.",
      
    },
    {
      img: "assets/img/about/acerca-de-ganancias.png",
      name: "Ganancias.",
      
    },
    { 
      img: "assets/img/about/acerca-de-datos-acumulables.png",
      name: "Paquetes de datos acumulables.",
      
    }
  ]

  vals1 = [
    { img: "assets/img/about/acerca-de-valores-diversion.png",
      name: "DIVERSIÓN",
      subtitle: "Incorporamos elementos entretenidos, interesantes y alegres, para actuar con entusiasmo, en la consecución de nuestras metas.",
      
    },
    { img: "assets/img/about/acerca-de-valores-innovacion.png",
      name: "INNOVACIÓN",
      subtitle: "Nos retamos constantemente para desarrollar nuevos productos y servicios para aumentar la ganancia de nuestros usuarios.",
      
    },
    { img: "assets/img/about/acerca-de-valores-transparencia.png",
      name: "TRANSPARENCIA",
      subtitle: "Cumplimos lo que ofrecemos.",
      
    },
  ]

  vals2 = [
    { img: "assets/img/about/acerca-de-valores-respeto.png",
      name: "RESPETO",
      subtitle: "Reconocemos el valor de los que nos rodean al mismo tiempo que reconocemos lo que valemos.",
      
    },
    { img: "assets/img/about/acerca-de-valores-resilencia.png",
      name: "RESILENCIA",
      subtitle: "Capacidad de afrontar la adversidad y mantener tu compostura en momentos difíciles.",
      
    },
    { img: "assets/img/body/yoko-giratorio-con-aro_v3.gif",
      name: "Somos YOKOFON",
      subtitle: "",
      
    },
  ]
  
  teamcarouselOptions= {
    items: 5,
    margin: 30,
    autoHeight: true,
    nav: false,
    dots:false,
    autoplay: false,
    slideSpeed: 300,
    paginationSpeed: 400,
    loop: true,
    responsive: {
        0: {
            items: 1
        },
        576: {
            items: 2,
            margin: 10
        },
        991: {
            items: 3,
            margin: 10
        }
    }
  }
  constructor() { }

  ngOnInit(): void {
  }

}
