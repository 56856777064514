import { Component, OnInit } from '@angular/core';
import { fadeInLeft, fadeInRight } from 'ng-animate';
import { transition, trigger, useAnimation } from '@angular/animations';
import { ColorScssService } from '../shared/service/color-scss.service';

@Component({
  selector: 'app-carrera',
  templateUrl: './carrera.component.html',
  styleUrls: ['./carrera.component.scss'],
  animations: [
    trigger('fadeInLeft', [transition('* => *', useAnimation(fadeInLeft, {
      // Set the duration to 5seconds and delay to 0seconds
      params: { timing: 1, delay: 0 }
    }))]),
    trigger('fadeInRight', [transition('* => *', useAnimation(fadeInRight, {
      // Set the duration to 5seconds and delay to 0seconds
      params: { timing: 1, delay: 0 }
    }))]),
  ],
})
export class CarreraComponent implements OnInit {
  fadeInLeft:any;
  fadeInRight:any;
  users = [
    { 
      img:"assets/img/body/carrera-novato-aro.png",
      name:"NOVATO",
      designation:"Ganas 0 YOKOS adicionales",
    },
    { 
      img:"assets/img/body/carrera-retador-aro.png",
      name:"RETADOR",
      designation:"Ganas 1 YOKO adicional",
    },
    { 
      img:"assets/img/body/carrera-campeon-aro.png",
      name:"CAMPEÓN",
      designation:"Ganas 2 YOKOS adicionales",
    },
    { 
      img:"assets/img/body/carrera-maestro-aro.png",
      name:"MAESTRO",
      designation:"Ganas 3 YOKOS adicionales",
    },
    
    { 
      img:"assets/img/body/carrera-lider-aro.png",
      name:"LIDER",
      designation:"Ganas 4 YOKOS adicionales",
    }
  ]


  constructor(public colorPicker: ColorScssService) { }

  ngOnInit(): void {
    this.colorPicker.setColorScheme('color-2');
  }

}
