import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-music-album',
  templateUrl: './music-album.component.html',
  styleUrls: ['./music-album.component.scss']
})
export class MusicAlbumComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
