import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gym-nav',
  templateUrl: './gym-nav.component.html',
  styleUrls: ['./gym-nav.component.scss']
})
export class GymNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
