import { Component, OnInit, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ColorScssService } from "../shared/service/color-scss.service";
import postscribe from "postscribe";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private title: Title,
    public colorPicker: ColorScssService
  ) { }
  isHidden = false;
  isMobile = window.screen.width < window.screen.height
  ngOnInit(): void {
    this.title.setTitle(this.route.snapshot.data["title"]);
    this.colorPicker.setColorScheme("color-2");
    const element = <HTMLElement>document.getElementsByClassName("b24-widget-button-wrapper b24-widget-button-position-bottom-right b24-widget-button-visible")[0]
    console.log("on home")
    if (element) {
      console.log("exist")
      element.style.display = "none"
    }
    if (window.screen.height < window.screen.width) {
      this.isHidden = true;
    }
    window.addEventListener("scroll", this.scroll, true);
    ;
  }
  ngOnDestroy() {
    window.removeEventListener("scroll", this.scroll, true);
  }
  scroll = (): void => {
    console.log({ y: window.scrollY })
    if (window.scrollY > 40) {
      this.isHidden = true;
    } else {
      this.isHidden = false;
    }
    //handle your scroll here
    //notice the 'odd' function assignment to a class field
    //this is used to be able to remove the event listener
  };
  href(path: string | boolean = false) {

  }
  refresh(): void {
    window.location.reload();
  }
}
