import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ColorScssService } from "../shared/service/color-scss.service";
import postscribe from "postscribe";
import { AngularFirestore } from "@angular/fire/firestore";

@Component({
  selector: "app-faqs",
  templateUrl: "./faqs.component.html",
  styleUrls: ["./faqs.component.scss"],
})
export class FaqsComponent implements OnInit {
  constructor(
    public colorPicker: ColorScssService,
    private firestore: AngularFirestore
  ) {}
  hidden = true;
  location = window.location.pathname;
  public faqs = [];
  ngOnInit(): void {
    this.firestore
      .collection("Config")
      .doc("Global")
      .collection("Faqs")
      .snapshotChanges()
      .subscribe((query) => {
        this.faqs = query.map((snap: any) => snap.payload.doc.data());
      });

    this.colorPicker.setColorScheme("color-2");
    let text = `<script>
    (function(w,d,u){
      if(w.location.pathname === "/faqs"){
          var s=d.createElement('script');
          s.async=true;s.src=u+'?'+(Date.now()/60000|0);
          s.id="widget-b24";
          s.onload = function( evt ) {
          console.log("onload", w.location.pathname)
          this.parentNode.removeChild (this);
          this.src = ""
          this.type = "text/someLang"
        }
        var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
      }
    })(window,document,'https://portal.yokofon.com/upload/crm/site_button/loader_3_k7xql2.js');
   
</script>`;
    postscribe("#form-widget", text);
  }
}
