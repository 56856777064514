import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';




@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})

export class RedirectComponent implements OnInit {
  // variables
  code = null
  constructor(private route: ActivatedRoute) { }



  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.code = params.get('code');
      const base = `https://felgtaws.digifact.com.gt/guest/api`
      const url = `${base}/FEL?DATA=44653948%${this.code}%GUESTUSERQR`

      window.location.href = url

    });
  }







}
