<!-- subscribe section start-->
<section class="yoga subscribe bg img-1" id="contact" [ngStyle]="{'background-image': 'url(assets/images/yoga-img/schedule-bg.jpg)'}">
  <div class="container">
      <div class="row">
          <div class="col-lg-8 offset-lg-2">
              <div class="title title2">
                  <h3 class="text-white subscribe-head">Sign up For A Test Leson And Start Yoga Today !</h3>
                  <p class="text-white subscribe-sub-head ">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                      Lorem Ipsum has been</p>
              </div>
          </div>
          <div class="col-lg-6 offset-lg-3 col-md-8 offset-md-2">
              <div class="subscribe">
                  <div class="center-content">
                      <div class="form-group">
                          <div class="d-flex">
                              <input type="email" class="form-control text-capitalize" placeholder="Please Enter Your Email Address"
                                     name="email">
                              <div class="button-primary">
                                  <button type="submit" class=" btn btn-default btn-gradient text-white text-capitalize">send me</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- subscribe section end-->
