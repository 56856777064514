import { CommonModule } from '@angular/common';
import { Component, OnInit, } from '@angular/core';
import { ColorScssService } from '../shared/service/color-scss.service';

@Component({
  selector: 'app-yokos',
  templateUrl: './yokos.component.html',
  styleUrls: ['./yokos.component.scss']
})
export class YokosComponent implements OnInit {
  public work: any = 'Configuration'
    isMobile = window.screen.width < window.screen.height

  nav = [
    {
      img:'assets/images/saas1/tab-icon/installation.png',
      title:'Initializing'
    },
    {
      img:'assets/images/saas1/tab-icon/001-tap.png',
      title:'Add Instances'
    },
    {
      img:'assets/images/saas1/tab-icon/button.png',
      title:'Configuration'
    },
    {
      img:'assets/images/saas1/tab-icon/002-settings.png',
      title:'API Setting'
    },
    {
      img:'assets/images/saas1/tab-icon/key-1.png',
      title:'Security'
    }
  ]

  content=[
    {
      img:'assets/images/saas1/tab/Initializing.png',
      title:'Initializing'
    },
    {
      img:'assets/images/saas1/tab/Add-instant.png',
      title:'Add Instances'
    },
    {
      img:'assets/images/saas1/tab/Configuration.png',
      title:'Configuration'
    },
    {
      img:'assets/images/saas1/tab/Api.png',
      title:'API Setting'
    },
    {
      img:'assets/images/saas1/tab/Security.png',
      title:'Security'
    }
  ]
  constructor(public colorPicker: ColorScssService) { }

  ngOnInit() {
    this.colorPicker.setColorScheme('color-2');
  }

  openWorkSlide(val){
    this.work = val
  }


}
