<app-navbar></app-navbar>
<section class="yk-primary">
  <div class="div-beneficios-sm center-content">
    <br />
    <br />
    
    <br />
  </div>
  <div class="container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-12 text-center">
        <h4>Es un emprendimiento para emprendedores.</h4>
      </div>
    </div>
    <br />
    <br />
    <br />
    <section class="app2 team p-t-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <owl-carousel-o [options]="teamcarouselOptions" class="team-slider">
              <ng-container *ngFor="let team of teams">
                <ng-template carouselSlide class="item">
                  <div class="team-container">
                    <img [src]="team.img" alt="" class="img-fluid" />
                    <div class="text-center">
                      <h5 class="name">{{ team.name }}</h5>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>

<!-- mision -->
<section class="section section-lg section-hero section-shaped">
  <!-- Background circles -->

  <div class="container shape-container d-flex align-items-center">
    <div class="col px-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 text-center">
          <img
            src="./assets/img/about/acerca-de-nuestra-mision.png"
            style="width: 100%;"
            class="img-fluid"
          />
        </div>

        <div class="col-lg-6 text-center">
          <h3 class="yk-text-black font-weight-bold" id="misionTitle">
            Nuestra Misión
          </h3>
          <h6 class="yk-text-black font-weight-bold">
            Lograr que las personas descubran en su dispositivo móvil, una
            máquina para generar ganancias.
          </h6>
          <div class="btn-wrapper mt-5"></div>
        </div>
      </div>
    </div>
  </div>
  <!-- SVG separator -->
</section>
<!-- vision -->
<section class="section section-lg section-hero section-shaped yk-primary">
  <!-- Background circles -->
  <div class="shape shape-style-1"></div>
  <div class="container shape-container d-flex align-items-center">
    <div class="col px-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6 text-center">
          <h3 class="yk-text-black font-weight-bold" id="visionTitle">
            Nuestra Visión
          </h3>
          <h6 class="yk-text-black font-weight-bold">
            Crear oportunidades en las que todos puedan elegir ganar.
          </h6>
          <div class="btn-wrapper mt-5"></div>
        </div>
        <div class="col-lg-6 text-center">
          <img
            src="./assets/img/about/acerca-de-nuestra-vision.png"
            style="width: 100%;"
            class="img-fluid"
          />
        </div>
      </div>
    </div>
  </div>
  <!-- SVG separator -->
</section>
<!-- valores -->
<section class="section section-lg section-hero section-shaped">
  <!-- Background circles -->
  <div class="shape shape-style-1 shape-primary"></div>
  <div class="yk-container">
    <div class="row align-items-center justify-content-center">
      <div class="col-lg-12 text-center">
        <h1 class="yk-primary"><i class="ni ni-favourite-28"></i></h1>
        <h2 id="valoresTitle">Nuestros Valores</h2>
      </div>
    </div>
    
    <section class="app2 team p-t-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <owl-carousel-o [options]="teamcarouselOptions" class="team-slider">
              <ng-container *ngFor="let valfirst of vals1">
                <ng-template carouselSlide class="item">
                  <div class="team-container">
                    <img [src]="valfirst.img" alt="" class="img-fluid" />
                    <div class="text-center">
                      <h5 class="name">{{ valfirst.name }}</h5>
                      <p class="name">{{ valfirst.subtitle }}</p>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </section>
    <section class="app2 team p-t-0">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <owl-carousel-o [options]="teamcarouselOptions" class="team-slider">
              <ng-container *ngFor="let valfirst of vals2">
                <ng-template carouselSlide class="item">
                  <div class="team-container">
                    <img [src]="valfirst.img" alt="" class="img-fluid" />
                    <div class="text-center">
                      <h5 class="name">{{ valfirst.name }}</h5>
                      <p class="name">{{ valfirst.subtitle }}</p>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>


