<footer class="ecommerce-footer">
    <div class="container">
        <div class="row footer-theme partition-f">
            <div class="col-lg-4 col-md-12">
                <div class="footer-title footer-mobile-title">
                    <h4>about</h4>
                </div>
                <div class="footer-contant">
                    <div class="footer-logo"><img alt="" src="assets/images/logo/1.png"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam,</p>
                    <div class="footer-social">
                        <ul>
                            <li><a href="#"><i aria-hidden="true" class="fa fa-facebook"></i></a></li>
                            <li><a href="#"><i aria-hidden="true" class="fa fa-google-plus"></i></a></li>
                            <li><a href="#"><i aria-hidden="true" class="fa fa-twitter"></i></a></li>
                            <li><a href="#"><i aria-hidden="true" class="fa fa-instagram"></i></a></li>
                            <li><a href="#"><i aria-hidden="true" class="fa fa-rss"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col offset-xl-1">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>my account</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li><a href="#">mens</a></li>
                            <li><a href="#">womens</a></li>
                            <li><a href="#">clothing</a></li>
                            <li><a href="#">accessories</a></li>
                            <li><a href="#">featured</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>why we choose</h4>
                    </div>
                    <div class="footer-contant">
                        <ul>
                            <li><a href="#">shipping &amp; return</a></li>
                            <li><a href="#">secure shopping</a></li>
                            <li><a href="#">gallary</a></li>
                            <li><a href="#">affiliates</a></li>
                            <li><a href="#">contacts</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="sub-title">
                    <div class="footer-title">
                        <h4>store information</h4>
                    </div>
                    <div class="footer-contant">
                        <ul class="contact-list">
                            <li><i class="fa fa-map-marker"></i>Unice Demo Store, Demo store India 345-659</li>
                            <li><i class="fa fa-phone"></i>Call Us: 123-456-7898</li>
                            <li><i class="fa fa-envelope-o"></i>Email Us: Support@Fiot.com</li>
                            <li><i class="fa fa-fax"></i>Fax: 123456</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="sub-footer">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="footer-end">
                        <p><i aria-hidden="true" class="fa fa-copyright"></i> 2017-18 Unice powered by
                            pixelstrap</p>
                    </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                    <div class="payment-card-bottom">
                        <ul>
                            <li>
                                <a href="#"><img alt="" src="assets/images/icon/visa.png"></a>
                            </li>
                            <li>
                                <a href="#"><img alt="" src="assets/images/icon/mastercard.png"></a>
                            </li>
                            <li>
                                <a href="#"><img alt="" src="assets/images/icon/paypal.png"></a>
                            </li>
                            <li>
                                <a href="#"><img alt="" src="assets/images/icon/american-express.png"></a>
                            </li>
                            <li>
                                <a href="#"><img alt="" src="assets/images/icon/discover.png"></a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
<app-tap-to-top></app-tap-to-top>
