<!-- booking Start-->
<section class="event booking set-relative" id="register">
    <div class="circle">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2><span>Book</span> Your Ticket</h2>
                    </div>
                    <div class="sub-title">
                        <P>Register now and reserve your seat for this <span>year's unice,</span> the
                            largest <span>web development</span> and online marketing <span>conference in UK,</span>
                            covering everything you need to know in order to develop a successful online business.</P>
                    </div>
                </div>
            </div>
            <div class="col-12">
                <form class="form-inline m-b-50">
                    <div class="col">
                        <div class="form-group">
                            <input type="text" placeholder="first name">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" placeholder="last name">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" placeholder="email">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" placeholder="mobile">
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <input type="text" placeholder="company name">
                        </div>
                    </div>
                </form>

                <div class="text-center">
                    <a class="btn btn-default primary-btn m-0-auto" href="#">register</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- booking end-->
