import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ChipComponent } from './chip/chip.component';
import { YokosComponent } from './yokos/yokos.component';
import { OfertaComponent } from './oferta/oferta.component';
import { CarreraComponent } from './carrera/carrera.component';
import { YokoliderComponent } from './yokolider/yokolider.component';
import { FaqsComponent } from './faqs/faqs.component';
import { PuntosComponent } from './puntos/puntos.component';
import { AcercaDeComponent } from './acerca-de/acerca-de.component';
import { CompraComponent } from './compra/compra.component';
import { RedirectComponent } from './redirect/redirect.component';
import { CustomRedirectComponent } from './custom-redirect/custom-redirect.component';
import { DocumentacionComponent } from "./documentacion/documentacion.component"
export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'chip',
    component: ChipComponent
  },
  {
    path: 'yokos',
    component: YokosComponent
  },
  {
    path: 'oferta',
    component: OfertaComponent
  },
  {
    path: 'carrera',
    component: CarreraComponent
  },
  {
    path: 'yokolider',
    component: YokoliderComponent
  },
  {
    path: 'faqs',
    component: FaqsComponent
  },
  {
    path: 'puntos',
    component: PuntosComponent
  },
  {
    path: 'acerca',
    component: AcercaDeComponent
  },
  { path: 'buy/:productId', component: CompraComponent },
  { path: 'factura/:code', component: RedirectComponent,  pathMatch: 'full' },
  { path: 'r/:path', component: CustomRedirectComponent,  pathMatch: 'full' },
  { path: 'documentacion', component: DocumentacionComponent,  pathMatch: 'full' }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    useHash: true
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
