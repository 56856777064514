<app-ecommerce-header></app-ecommerce-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Product Pages</h2>
                        </div>
                        <div>
                            <ul>
                                
                                <li><a href="#">Product Pages</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>Left Sidebar</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!--breadcrumb section end -->
<!-- section start -->
<div class="agency">
    <section>
        <div class="collection-wrapper">
            <div class="container">
                <div class="row">
                <a  class="overlay-sidebar" [class.overlay-sidebar-open]="productService.filterBar" (click)="closeOverlay()"></a>
                    <div class="col-sm-3 collection-filter" [class.filterBar]="productService.filterBar">
                      <app-sidebar></app-sidebar>
                    </div>
                    <div class="col-lg-9 col-sm-12 col-xs-12">
                        <app-product-details></app-product-details>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </div>
    <!-- Section ends -->
<app-ecommerce-footer></app-ecommerce-footer>
