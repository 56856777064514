<!--schedule section start-->
<section class="yoga format bg img-1" [ngStyle]="{'background-image': 'url(assets/images/yoga-img/schedule-bg.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title title2">
                    <h2 class="text-white">Yoga Schedule</h2>
                    <img src="assets/images/yoga-img/logo/bottom-curve.png" alt="" class="img-fluid">
                </div>
            </div>
            <div class="col-lg-5 col-md-6 set-order-2">
                <div class=" center-text">
                    <div>
                        <div class="format-head-text"><h3 class="about-font-header text-white">#1 <span>monday</span></h3></div>
                        <div class="format-sub-text">
                            <p class="about-para text-white">Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                            <p class="about-para text-white">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</p>
                        </div>
                        <ul class="icon-collection">
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/yoga-img/icon/1.png" alt="" class="img-fluid"></a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/yoga-img/icon/2.png" alt="" class="img-fluid"></a>
                            </li>
                            <li class="about-icon">
                                <a href="#" class="center-content"><img src="assets/images/yoga-img/icon/3.png" alt="" class="img-fluid"></a>
                            </li>
                        </ul>
                        <a class="btn btn-default back-white m-t-45">learn more</a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-6">
                <div class="girl-img-container">
                    <img src="assets/images/yoga-img/sechdule/img.png" class="img-fluid girl-yoga" alt="">
                </div>
            </div>
        </div>
    </div>
</section>
<!--schedule section end-->
