<div class="bg-black">
<app-music-nav></app-music-nav>
<app-music-header></app-music-header>
<app-music-album></app-music-album>
<app-music-artist></app-music-artist>
<app-music-sponsor></app-music-sponsor>
<app-music-gallery></app-music-gallery>
<app-music-video></app-music-video>
<app-music-booking></app-music-booking>
<app-music-blog></app-music-blog>
<app-music-subscribe></app-music-subscribe>
<app-music-testimonial></app-music-testimonial>
<app-music-footer></app-music-footer>
<app-music-copyright></app-music-copyright>
</div>