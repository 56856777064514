<!--about start-->
<section class="resume about" id="about">
  <div class="container">
      <div class="title title2">
          <div class="row">
              <div class=" offset-md-2 col-md-8">
                  <h6 class="font-primary borders main-text text-uppercase"><span>about</span></h6>
                  <div class="sub-title">
                      <div class="">
                          <h2 class="title-text">About me</h2>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="row">
          <div class="col-md-3 col-6"  *ngFor="let data of about">
              <div class="text-center radial">
                  <div data-label="95%" class="radial-bar radial-bar-primary">
                  </div>
                  <h4 class="color-light m-t-15">{{data.name}}</h4>
                  <div class="border-container">
                      <div class="borders-bottom"></div>
                  </div>
                  <div>
                      <h4>{{data.exp}}</h4>
                  </div>
              </div>
          </div>
      </div>
      <div class="text-center m-t-50">
          <a class="btn btn-default primary-btn">know more now</a>
      </div>
  </div>
</section>
<!--about end-->
