// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD0eemHHPvKCnBsSe8ZQqQ-OnDPvbILCsU',
    authDomain: 'swift-shore-249122.firebaseapp.com',
    databaseURL: 'https://swift-shore-249122.firebaseio.com',
    projectId: 'swift-shore-249122',
    storageBucket: 'swift-shore-249122.appspot.com',
    messagingSenderId: '821764841285',
    appId: '1:821764841285:web:bf8edce8909213989f682c',
    measurementId: 'G-DM5Q73VQJT'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
