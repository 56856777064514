<a class="overlay-sidebar-header" [class.overlay-sidebar-header-open]="openSide" (click)="closeOverlay()"></a>
<div class="responsive-btn">
    <a  href="/home">
        <i class="fa fa-home text-white" aria-hidden="true"></i>
    </a>
</div>
<div class="navbar m-l-auto" id="togglebtn" [class.openSidebar]="openSide">
    <div class="responsive-btn">
        <a (click)="toggleSidebar()" style="color: red;">
            <h5>Cerrar</h5>
        </a>
    </div>
    <ul class="main-menu">
        <a  href="/home">
            <i class="fa fa-home text-white" aria-hidden="true" style="font-size: 35px;"></i>
        </a>
        <!-- 1st Level Menu -->
        <li *ngFor="let menuItem of []" [class]="menuItem.megaMenu ? 'mega-menu' : 'simple-menu'">
            <!-- Sub -->
            <a href="javascript:void(0)" *ngIf="menuItem.type === 'sub'" class="dropdown"
                (click)="setActive(menuItem.title)">
                {{menuItem.title}}
            </a>
            <!-- Link -->
            <a [routerLink]="!menuItem.type ? null : [menuItem.path]" routerLinkActive="active"
                class="dropdown button-nav" *ngIf="menuItem.type === 'link'">
                <b>
                    {{menuItem.title}}
                </b>

            </a>
            <!-- External Link -->
            <a href="{{ !menuItem.type ? null : menuItem.path }}" class="dropdown" *ngIf="menuItem.type === 'extLink'">
                {{menuItem.title}}
            </a>
            <!-- External Tab Link -->
            <a href="{{ !menuItem.type ? null : menuItem.path }}" *ngIf="menuItem.type === 'extTabLink'">
                {{menuItem.title}}
            </a>
            <!-- Mega menu -->
            <div class="mega-menu-container" [class.opensubmenu]="isActive(menuItem.title)"
                *ngIf="menuItem.megaMenu && menuItem.megaMenuType == 'small' || menuItem.megaMenuType == 'medium' || menuItem.megaMenuType == 'large'">
                <div class="container">
                    <div class="row">
                        <div [class]="menuItem.megaMenuType == 'small' ? 'col-lg-4' : menuItem.megaMenuType == 'medium' ? 'col-lg-3' : menuItem.megaMenuType =='large'? 'col' :'' "
                            *ngFor="let childrenItem of menuItem.children">
                            <div class="menu-container">
                                <a class="menu-head"
                                    (click)="setChildActive(childrenItem.title)">{{childrenItem.title}}</a>
                                <ul [class.menu-icon]="menuItem.title == 'Elements'"
                                    [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                                    <li *ngFor="let subChildrenItem of childrenItem.children">
                                        <a [routerLink]="!subChildrenItem.type ? null : [subChildrenItem.path]"
                                            routerLinkActive="active" *ngIf="subChildrenItem.type === 'link'">
                                            <i class="icon-{{subChildrenItem.icon}}"></i>{{subChildrenItem.title}}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 2nd Level Menu -->
            <ul *ngIf="menuItem.children && !menuItem.megaMenu" [class.opensubmenu]="isActive(menuItem.title)">
                <!-- Simple Menu Start-->
                <li *ngFor="let childrenItem of menuItem.children" [class.sub-menu]="childrenItem.children">
                    <!-- Sub -->
                    <a href="javascript:void(0)" *ngIf="childrenItem.type === 'sub'"
                        (click)="setChildActive(childrenItem.title)">
                        {{childrenItem.title}}
                    </a>
                    <!-- Link -->
                    <a [routerLink]="!childrenItem.type ? null : [childrenItem.path]" routerLinkActive="active"
                        *ngIf="childrenItem.type === 'link' && !menuItem.megaMenu">
                        {{childrenItem.title}}
                    </a>
                    <!-- External Link -->
                    <a href="{{ !childrenItem.type ? null : childrenItem.path }}"
                        *ngIf="childrenItem.type === 'extLink' && !menuItem.megaMenu">
                        {{childrenItem.title}}
                    </a>
                    <!-- External Tab Link -->
                    <a href="{{ !childrenItem.type ? null : childrenItem.path }}" target="_blank"
                        *ngIf="childrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                        {{childrenItem.title}}
                    </a>
                    <ul *ngIf="childrenItem.children" [class.openSubChildMenu]="ischildActive(childrenItem.title)">
                        <li *ngFor="let subChildrenItem of childrenItem.children">
                            <!-- Sub -->
                            <a href="javascript:void(0)" *ngIf="subChildrenItem.type === 'sub'">
                                {{subChildrenItem.title}}
                            </a>
                            <!-- Link -->
                            <a [routerLink]="!subChildrenItem.type ? null : [subChildrenItem.path]"
                                routerLinkActive="active" *ngIf="subChildrenItem.type === 'link' && !menuItem.megaMenu">
                                {{subChildrenItem.title}}
                            </a>
                            <!-- External Link -->
                            <a href="{{ !subChildrenItem.type ? null : subChildrenItem.path }}"
                                *ngIf="subChildrenItem.type === 'extLink' && !menuItem.megaMenu">
                                {{subChildrenItem.title}}
                            </a>
                            <!-- External Tab Link -->
                            <a href="{{ !subChildrenItem.type ? null : subChildrenItem.path }}" target="_blank"
                                *ngIf="subChildrenItem.type === 'extTabLink' && !menuItem.megaMenu">
                                {{subChildrenItem.title}}
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>

        </li>
    </ul>
    <div class="end logo-bottom">
        <a href="#" class="d-inline-block">
            <img src="assets/images/logo-yokofon.png" alt="" class="img-fluid div-beneficios-bg"
                style="width: 200px;" />
        </a>
    </div>
</div>