<!--contact section start-->
<footer class="event contact set-relative bg bg-img bg-about p-b-0" id="contact" [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container p-b-150">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white">Contact</h2>
                    </div>
                </div>
            </div>
            <div class="col-xl-5 offset-xl-1 col-md-6 p-r-0 map">
                <div class="iframe-container">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d2965.0824050173574!2d-93.63905729999999!3d41.998507000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sWebFilings%2C+University+Boulevard%2C+Ames%2C+IA!5e0!3m2!1sen!2sus!4v1390839289319"
                    ></iframe>
                </div>
            </div>
            <div class="col-xl-5 p-l-0 col-md-6 set-z-index form-footer">
                <div class="bg-white">

                    <form>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="name">Name *</label>
                                    <input type="text" class="form-control" id="name">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Phone">Phone *</label>
                                    <input type="text" class="form-control" id="Phone">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Email">Email *</label>
                                    <input type="text" class="form-control" id="Email">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="Subject">Subject *</label>
                                    <input type="text" class="form-control" id="Subject">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="Message">Message *</label>
                            <input type="text" class="form-control" id="Message">
                        </div>
                        <a class="btn btn-default primary-btn m-0-auto">send</a>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <img src="assets/images/event/footer/2.png" alt="" class="set-abs top-0 plane2">
    <img src="assets/images/event/footer/1.png" alt="" class="set-abs bottom-0 plane">
</footer>
<!--contact section end-->
