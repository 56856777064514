import { Component, OnInit } from '@angular/core';
import { ColorScssService } from '../shared/service/color-scss.service';
import { json } from 'express';

@Component({
  selector: 'app-puntos',
  templateUrl: './puntos.component.html',
  styleUrls: ['./puntos.component.scss']
})
export class PuntosComponent implements OnInit {

  constructor(public colorPicker: ColorScssService) { }
  Latitude = 14.601836
  Longitude = -90.501363
  findit = false
  markers = []
  zoom = 14
  icon = "assets/img/icons/googlemaps_small.png"
  async getMarkers(): Promise<Array<Object>> {
    const header = new Headers();

    const options: RequestInit = {
      method: 'POST',
      headers: header,
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify({
        Latitude: String(this.Latitude),
        Longitude: String(this.Longitude),
        apikey: "sudo"
      })
    };
    const request = await fetch("https://us-central1-swift-shore-249122.cloudfunctions.net/USER/App/PointsOfSales", options)
    const response = await request.json()
    return response.content
  }

  ngOnInit(): void {
    const self = this

    this.getMarkers().then(res => res.forEach(l => self.markers.push(l)))
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position: Position) => {
        self.findit =  true
        self.Latitude = position.coords.latitude
        self.Longitude = position.coords.longitude
      })
    }
    this.colorPicker.setColorScheme('color-2');
  }


}
