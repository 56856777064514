import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-resume-subscribe',
  templateUrl: './resume-subscribe.component.html',
  styleUrls: ['./resume-subscribe.component.scss']
})
export class ResumeSubscribeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
