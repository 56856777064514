import { HttpClientModule } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AppRoutingModule, routes } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { ShopModule } from './shop/shop.module';
import { NavButton } from 'ngx-owl-carousel-o/lib/models/navigation-data.models';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
import { OfertaComponent } from './oferta/oferta.component';
import { CarreraComponent } from './carrera/carrera.component';
import { YokoliderComponent } from './yokolider/yokolider.component';
import { FaqsComponent } from './faqs/faqs.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PuntosComponent } from './puntos/puntos.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ChipComponent } from './chip/chip.component';
import { YokosComponent } from './yokos/yokos.component';
import { AcercaDeComponent } from './acerca-de/acerca-de.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CompraComponent } from './compra/compra.component';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { AgmCoreModule } from '@agm/core';


@NgModule({
  declarations: [	
    AppComponent,
    HomeComponent,
    NavbarComponent,
    FooterComponent,
    OfertaComponent,
    CarreraComponent,
    YokoliderComponent,
    FaqsComponent,
    PuntosComponent,
    ChipComponent,
    YokosComponent,
    AcercaDeComponent,
    CompraComponent
   ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCnMqQT0fR83DVjw9H9ZbSQZCTJHT8PfdY'
    }),
    RecaptchaV3Module,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    ShopModule,
    NgxSpinnerModule,
    HttpClientModule,
    CarouselModule,
    ScrollToModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    SharedModule,
    SlickCarouselModule,
    NgbModule,
    RouterModule.forRoot(routes, { useHash: false, anchorScrolling: 'enabled', scrollPositionRestoration: 'enabled' })
  ],
  providers: [{ provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LdKELAZAAAAAI6uyX3u7K3Fbta91c_oqszZ1PqH' },],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
