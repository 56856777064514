<!-- header section Start-->
<section class="wedding header" id="header">
  <div class="decore">
      <img src="assets/images/wedding-img/backgrounds/top-pattern.png" class="img-fluid" alt="">
      <img src="assets/images/wedding-img/backgrounds/bottom-pattern.png" class="img-fluid" alt="">
  </div>
  <div class="wedding-content">
      <div class="wedding bg slider-bg"  [ngStyle]="{'background-image': 'url(assets/images/wedding-img/slider/slider-bg.jpg)'}">
        <div  aTilt data-tilt data-tilt-max="3" data-tilt-speed="10"
        data-tilt-perspective="500">
          <div class="bottom-0 set-abs girl"><img id="girl" src="assets/images/wedding-img/slider/girl.png" class="img-fluid" alt=""></div>
          <div class="container">
              <div class="row">
                  <div class="col-lg-5 offset-lg-7">
                      <div class="center-text m-t-50">
                          <div class="simple-text">
                              <div class="set-relative rightFadeInOut header-text">
                                  <img src="assets/images/wedding-img/logo-h1.png" alt="" class="logo-h1">
                              </div>
                              <p class="header-sub-text">Lorem Ipsum is simply dummy text of the printing and
                                  typesetting industry. Lorem Ipsum has been the industry's standard dummy text. </p>
                              <div class="rightfadediv">
                                  <div>
                                      <img src="assets/images/wedding-img/bottom.png" alt="" class="img-fluid bottom-img">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          </div>
      </div>
  </div>
</section>
<!-- header section end-->
