<section class="down-banner">
    <div class="container">
        <div class="row">
            <div class="col-12 p-0">
                <div class="small-banner">
                    <img alt="full banner" class="bg-img" src="assets/images/ecommerce/full-banner2.jpg">
                </div>
            </div>
            <div class="container">
                <div class="banner-contain">
                    <div>
                        <h3>Buy it and get 50% discount</h3>
                        <a class="btn btn-default primary-btn  radius-0 mt-3" href="#">View all</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>