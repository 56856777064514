<!-- about section Start-->
<section class="gym format">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="text-center center-content">
                    <div class="about-img">
                        <div class="borders-around">
                            <div>
                                <div class="bg-black"></div>
                            </div>
                            <img src="assets/images/gym/about.png" alt="" class="img-fluid format-img">
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class=" center-text">
                    <div>
                        <div class="format-small-text">
                            <h6 class="font-primary borders-before"><span>our progress</span></h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about us</h3>
                        </div>
                        <div class="format-sub-text">
                            <div class="sub-heading">
                                <h4>1. Trx Workout</h4>
                            </div>
                            <p class="p-light about-para">Lorem Ipsum is simply dummy text of the printing and
                                typesetting
                                industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                            <div class="sub-heading">
                                <h4>2. Posture Correction</h4>
                            </div>
                            <p class="p-light about-para">When an unknown printer took a galley of type and scrambled it
                                to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-default primary-btn">BOOK WORKOUT</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
