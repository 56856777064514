<app-navbar></app-navbar>

<section
  id="faq"
  class="saas1 faq testimonial-bg"
  style="background-color: #f1f1f1"
>
  <div class="container centered">
    <div class="row">
      <div class="col-md-12">
        <div class="text-center" style="width: 100%; margin-top: 20px">
          <h3 class="yk-text-black">¡Centro de ayuda!</h3>
          <span
            >Si no encuentras lo que buscas<br />
            puedes chatearnos en linea.</span
          >
        </div>
      </div>
      <div id="form-widget"></div>
      <div class="col-xs-12" style="padding: 10px; width: 100%">
        <div class="purple">
          <div class="centered">
            <div class="row centered">
              <div class="col-xs-12">
                <button
                  class="btn btn-white"
                  (click)="t.select('tab1')"
                  style="margin-top: 20px"
                >
                  <img src="assets/img/icons/pregunta.png" width="50px" />
                  <br />
                  <b> FAQ </b>
                </button>
              </div>
              <div class="col-xs-12">
                <button
                  class="btn btn-white"
                  (click)="t.select('tab2')"
                  style="margin-top: 20px"
                >
                  <img src="assets/img/icons/video.png" width="50px" />
                  <br />
                  <b> Videos </b>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card" style="padding: 10px; margin-top: 2vh">
          <div class="card-body">
            <ngb-tabset #t="ngbTabset" type="pills">
              <ngb-tab id="tab1" class="customTab">
                <ng-template ngbTabTitle class="tab-title-container"
                  ><span class="tab-title-custom"></span>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="faq-block">
                    <ngb-accordion
                      [closeOthers]="true"
                      class="accordion faq"
                      activeIds="0"
                      *ngFor="let faq of faqs"
                    >
                      <ngb-panel [id]="faq.Question" class="card">
                        <ng-template ngbPanelTitle class="card-header">
                          <div
                            class="collapsed"
                            data-toggle="collapse"
                            data-target="#collapse1"
                            aria-expanded="false"
                          >
                            <a>
                              <div class="fa fa-angle-right rotate"></div>
                            </a>
                            <p class="text-left">
                              <b> {{ faq.Question }} </b>
                            </p>
                          </div>
                        </ng-template>
                        <ng-template ngbPanelContent class="card-body">
                          {{ faq.Answer }}
                        </ng-template>
                      </ngb-panel>
                    </ngb-accordion>
                  </div>
                </ng-template>
              </ngb-tab>
              <ngb-tab id="tab2">
                <ng-template ngbTabTitle class="tab-title-container"
                  ><span class="tab-title-custom"></span>
                </ng-template>
                <ng-template ngbTabContent>
                  <div class="centered">
                    <div class="row">
                      <div class="col-xs-12 col-md-6 video-faq">
                        <div class="centered video-title">
                          <h4>Registrarte en el APP de yokofon</h4>
                        </div>
                        <video
                          width="100%"
                          controls
                          preload="none"
                          frameborder="0"
                        >
                          <source
                            src="assets\video\Expectativa01.mp4"
                            type="video/mp4"
                            autostart="false"
                          />
                        </video>
                      </div>
                      <div class="col-xs-12 col-md-6 video-faq">
                        <div class="centered video-title">
                          <h4>Registrarte en el APP de yokofon</h4>
                        </div>
                        <video
                          width="100%"
                          controls
                          preload="none"
                          frameborder="0"
                        >
                          <source
                            src="assets\video\Expectativa01.mp4"
                            type="video/mp4"
                            autostart="false"
                          />
                        </video>
                      </div>
                      <div class="col-xs-12 col-md-6 video-faq">
                        <div class="centered video-title">
                          <h4>Registrarte en el APP de yokofon</h4>
                        </div>
                        <video
                          width="100%"
                          controls
                          preload="none"
                          frameborder="0"
                        >
                          <source
                            src="assets\video\Expectativa01.mp4"
                            type="video/mp4"
                            autostart="false"
                          />
                        </video>
                      </div>
                      <div class="col-xs-12 col-md-6 video-faq">
                        <div class="centered video-title">
                          <h4>Recargar desde el APP de yokofon</h4>
                        </div>
                        <video
                          width="100%"
                          controls
                          preload="none"
                          frameborder="0"
                        >
                          <source
                            src="assets\video\Expectativa01.mp4"
                            type="video/mp4"
                            autostart="false"
                          />
                        </video>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ngb-tab>
            </ngb-tabset>
          </div>

          <!-- -->
        </div>
        <div style="margin-bottom: 100px">.</div>
      </div>
      <!-- <div class="col-xs-12 col-md-6">
        <div class="card" style="padding: 20px">
          <div class="text-center">
            <h4>Video Tutoriales</h4>

          </div>
        </div>
      </div> -->
    </div>
  </div>
  <!-- <div class="col-md-4">
            <div class="faq-img-block ">
              <img
                src="assets/img/body/banner-yokosocios-recargas.png"
                style="width: 80%;"
                class="  heartbeat "
              />
             
            </div>
          </div> -->
</section>
