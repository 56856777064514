<!--agency content start-->
<section class="agency agency-content about-bg">
    <h3 class="text-as-shadow">About <br/> us</h3>
    <div class="container">
        <div class="row">
            <div class="col-xl-9 offset-xl-3 col-md-9 offset-md-3">
                <div class="agency-header-center-container">
                    <div class="borders"></div>
                    <div class="agency-head">
                        <h3 class="agency-head-text">We Are Here <span class="block-span">For Made Your Idea</span>
                        </h3>
                    </div>
                </div>

                <p class="agency-para">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed
                    do eiusm od tempor
                    incididunt
                    ut labore et dolore magna aliqua ut enim adminim veni am for humanity for
                    savings
                    anything
                    for your future.</p>
                <a href="#" class=" btn btn-default btn-gradient text-white">view more</a>
            </div>
        </div>
    </div>
    <div class="side-img  set-abs left-0 top-0" [@fadeInLeft]="fadeInLeft">
        <div class="plus-container plus-container9">
            <div class="plus white">
            </div>
        </div>
        <div class="plus-container plus-container10">
            <div class="plus plus-medium white">
            </div>
        </div>
        <div class="plus-container plus-container11">
            <div class="plus plus plus-small white">
            </div>
        </div>
        <img src="assets/images/agency/about-us/image.png" alt="" class="img-fluid about-img">
    </div>
    <img src="assets/images/agency/agency-abs-img.png" alt="" class="img-fluid blue-img">
</section>
<!--agency content end-->
