<app-gym-nav></app-gym-nav>
<app-gym-header></app-gym-header>
<app-gym-about></app-gym-about>
<app-gym-schedule></app-gym-schedule>
<app-gym-workout-about></app-gym-workout-about>
<app-gym-counter></app-gym-counter>
<app-gym-trainer></app-gym-trainer>
<app-gym-testimonial></app-gym-testimonial>
<app-gym-pricing></app-gym-pricing>
<app-gym-bmi></app-gym-bmi>
<app-gym-brand></app-gym-brand>
<app-gym-footer></app-gym-footer>
<app-gym-copyright></app-gym-copyright>
