import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private REST_API_SERVER = 'https://us-central1-swift-shore-249122.cloudfunctions.net';
  constructor(private httpClient: HttpClient) { }

  public sendPostRequest(api: string, body: any): Observable<any> {
    return this.httpClient.post<any>(this.REST_API_SERVER + api, body);
  }

 

}
