import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agency-nav',
  templateUrl: './agency-nav.component.html',
  styleUrls: ['./agency-nav.component.scss']
})
export class AgencyNavComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
