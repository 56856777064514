<!-- Nav Start-->
<header class="app2 loding-header nav-abs custom-scroll yk-primary">
  <div class="container">
    <div class="row center-content">
      <div class="text-center">
        <div class="col-lg-12">
          <nav>
            <div>
              <a href="#" class="d-inline-block">
                <img
                  src="assets/images/logo-yokofon.png"
                  alt=""
                  class="img-fluid div-beneficios-bg"
                  style="width: 30%;"
                />
                <img
                  src="assets/images/logo-yokofon.png"
                  alt=""
                  class="img-fluid div-beneficios-sm"
                  style="width: 200px;"
                />
              </a>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="child">
      <app-menu></app-menu>
    </div>
  </div>
</header>
<!-- Nav end-->
