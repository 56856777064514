<!--testimonial section start-->
<section class="event testimonial set-relative bg bg-img bg-about"  [ngStyle]="{'background-image': 'url(assets/images/event/bg1.jpg)'}">
    <div class="container">
        <div class="row">
            <div class="col-md-10 offset-md-1">
                <div class="title title3">
                    <div class="main-title">
                        <h2 class=" text-white">testimonial</h2>
                    </div>
                    <div class="sub-title">
                        <P class="text-white">Register now and reserve your seat for this
                            <span>year's unice,</span> the largest <span>web development</span> and online marketing
                            <span>conference in UK,</span> covering everything you need to know in order to develop a
                            successful online business.</P>
                    </div>
                </div>
            </div>
            <div class="col-md-10 offset-md-1 margin-b">
                <owl-carousel-o [options]="testimonialCarouselOptions" class="testimonial">
                    <ng-container *ngFor="let user of users">
                        <ng-template carouselSlide >
                            <div class="p-t-30">
                            <div class="col-xl-10 offset-xl-1 col-12">
                                <div class="row">
                                    <div class="col-sm-3">
                                        <div class=" testimonial-msg set-relative">
                                            <img [src]="user.img" alt=""
                                                class="img-fluid">
                                            <div class="msg-box">
                                                <div class="center-content">
                                                    <img src="assets/images/event/testimonial/message.png" alt=""
                                                        class="img-fluid set-abs">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-9 px-lg-4">
                                        <div class="quotes set-relative m-b-30">
                                            <img src="assets/images/event/testimonial/i1.png" alt=""
                                                class="img-fluid set-abs left-quote">
                                            <div class="quote-text">
                                                <h5>"When you innovate, you make mistakes.It is best to admit them
                                                    quickly,
                                                    & get on with improving your other innovations."</h5>
                                            </div>
                                            <img src="assets/images/event/testimonial/i2.png" alt=""
                                                class="img-fluid set-abs right-quote">
                                        </div>
                                        <div class="rating align-items-center">
                                            <div class="stars">
                                                <ul>
                                                    <li>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star yellow" aria-hidden="true"></i>
                                                        <i class="fa fa-star" aria-hidden="true"></i>
                                                    </li>
                                                </ul>
                                            </div>
                                            <h5 class="name">Jhon Denal - <span> UI Designer</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>
        </div>
    </div>
</section>
<!--testimonial section end-->