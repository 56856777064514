import { Component, OnInit } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { faMobileAlt, faCloud } from "@fortawesome/free-solid-svg-icons";
declare var $: any;
import { SwiperConfigInterface } from "ngx-swiper-wrapper";

@Component({
  selector: "app-oferta",
  templateUrl: "./oferta.component.html",
  styleUrls: ["./oferta.component.scss"],
})
export class OfertaComponent implements OnInit {
  move = (id) => {
    window.location.href = "/buy/" + id;
  };
  active = 1;
  ProductID = "";
  packages;
  public slides = [];
  microDatos;
  microSaldo;
  microSMS;
  recharges;
  faCloud = faCloud;
  faMobileAlt = faMobileAlt;
  slideConfig = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1300,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  constructor(private firestore: AngularFirestore) {}

  ngOnInit(): void {
    const element = document.getElementById("next-right-btn");
    setInterval(() => {
      element.click();
    }, 3500);
    this.packages = [];
    const tmp = []
    this.firestore
      .collection("Products")
      .snapshotChanges()
      .subscribe((productSnapshot) => {
        productSnapshot.forEach((productData: any) => {
          if (productData.payload.doc.data().Type === "Package") {
            const data = productData.payload.doc.data();

            if (!this.ProductID) {
              this.ProductID = data.ProductID;
            }
            const assets = {
              YOKOPAQ50: "assets/img/body/YOKOPAQ-50.png",
              YOKOPAQ75: "assets/img/body/YOKOPAQ-75.png",
              YOKOPAQ100: "assets/img/body/YOKOPAQ-100.png",
              YOKOPAQ150: "assets/img/body/YOKOPAQ-150.png",
              YOKOPAQ250: "assets/img/body/YOKOPAQ-250.png",
            };
            tmp.push({
              data: { ...data, WebImage: assets[data.ProductID] },
            });
          }
        });
        this.packages = tmp.sort((a: any, b: any) => {
          const amount = a.data.Amount;
          const bmount = b.data.Amount;
          return Number(amount) - Number(bmount);
        });
      });

    this.firestore
      .collection("Products")
      .snapshotChanges()
      .subscribe((productSnapshot) => {
        this.microDatos = [];
        const tmp = [];

        productSnapshot.forEach((productData: any) => {
          if (
            productData.payload.doc.data().Type === "MicroPACK" &&
            productData.payload.doc.data().BucketType === "Data"
          ) {
            tmp.push({
              data: productData.payload.doc.data(),
            });
          }
        });
        this.microDatos = tmp.sort((a: any, b: any) => {
          const amount = a.data.Amount;
          const bmount = b.data.Amount;
          return Number(amount) - Number(bmount);
        });
      });
    this.firestore
      .collection("Products")
      .snapshotChanges()
      .subscribe((productSnapshot) => {
        this.microSMS = [];
        const tmp = [];

        productSnapshot.forEach((productData: any) => {
          if (
            productData.payload.doc.data().Type === "MicroPACK" &&
            productData.payload.doc.data().BucketType === "SMS"
          ) {
            tmp.push({
              data: productData.payload.doc.data(),
            });
          }
        });
        this.microSMS = tmp.sort((a: any, b: any) => {
          const amount = a.data.Amount;
          const bmount = b.data.Amount;
          return Number(amount) - Number(bmount);
        });
      });
    this.firestore
      .collection("Products")
      .snapshotChanges()
      .subscribe((productSnapshot) => {
        this.microSaldo = [];
        const tmp = [];

        productSnapshot.forEach((productData: any) => {
          if (
            productData.payload.doc.data().Type === "MicroPACK" &&
            productData.payload.doc.data().BucketType === "Voice"
          ) {
            tmp.push({
              data: productData.payload.doc.data(),
            });
          }
        });
        this.microSaldo = tmp.sort((a: any, b: any) => {
          const amount = a.data.Amount;
          const bmount = b.data.Amount;
          return Number(amount) - Number(bmount);
        });
      });

    this.firestore
      .collection("Products")
      .snapshotChanges()
      .subscribe((productSnapshot) => {
        this.recharges = [];
        const tmp = [];
        productSnapshot.forEach((productData: any) => {
          const doc = productData.payload.doc.data()
          if (doc.Type === "Recharge" && doc.Amount !== 10) {
            tmp.push({
              data: doc,
            });
          }
        });
        this.recharges = tmp.sort((a: any, b: any) => {
          const amount = a.data.Amount;
          const bmount = b.data.Amount;
          return Number(amount) - Number(bmount);
        });
      });
  }
  public index: any;

  public config: SwiperConfigInterface = {
    effect: "coverflow",
    loop: true,
    centeredSlides: true,
    slidesPerView: 4,
    initialSlide: 2,
    keyboard: true,
    mousewheel: false,
    preventClicks: false,
    preventClicksPropagation: false,
    coverflowEffect: {
      rotate: 0,
      stretch: 0,
      depth: 100,
      modifier: 1.43,
      slideShadows: false,
    },
    breakpoints: {
      1199: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
      991: {
        slidesPerView: 4,
      },
      767: {
        slidesPerView: 3,
      },
      575: {
        slidesPerView: 2,
      },
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: ".swiper-pagination",
    },
  };
}
