
<footer class="wedding copyright copyright-bg">
    <div class="container">
        <div class="row">
            <div class="col-xl-3 col-sm-12">
                <div class="text-center">
                    <div class="link-horizontal">
                        <ul class="">
                            <li>
                                <a href="#" class="copyright-text">
                                    <i class="fa fa-facebook gradient-text" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">
                                    <i class="fa fa-twitter gradient-text" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text">
                                    <i class="fa fa-google gradient-text" aria-hidden="true"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="copyright-text ">
                                    <i class="fa fa-instagram gradient-text" aria-hidden="true"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-sm-12">
                <div class="link-horizontal">
                <div class="footer-titles mobile-title">
                    <h3 class="">services<span class="according-menu"></span></h3>
                </div>
                    <ul class="link justify-content-center footer-contants">
                        <li>
                            <a href="#" class="copyright-text">home</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text">couple</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text">invitation</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text">album </a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text">friends say</a>
                        </li>
                        <li>
                            <a href="#" class="copyright-text">gift</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-xl-3 col-sm-12 p-0">
                <div class="m-l-auto center-para">
                    <h6 class="copyright-text text-right">© 2018 unice powered by Pixelstrap</h6>
                </div>
            </div>
        </div>
    </div>
</footer>

<app-tap-to-top></app-tap-to-top>