import { Component, OnInit } from '@angular/core';
import postscribe from 'postscribe';

@Component({
  selector: 'app-yokolider',
  templateUrl: './yokolider.component.html',
  styleUrls: ['./yokolider.component.scss']
})
export class YokoliderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    let text = `<script id="bx24_form_inline" data-skip-moving="true">
    (function(w,d,u,b){w['Bitrix24FormObject']=b;w[b] = w[b] || function(){arguments[0].ref=u;
            (w[b].forms=w[b].forms||[]).push(arguments[0])};
            if(w[b]['forms']) return;
            var s=d.createElement('script');s.async=1;s.src=u+'?'+(1*new Date());
            var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
    })(window,document,'https://portal.yokofon.com/bitrix/js/crm/form_loader.js','b24form');

    b24form({"id":"3","lang":"en","sec":"0slnn1","type":"inline"});
</script>`;
    postscribe('#myDiv2', text);
  }

}
