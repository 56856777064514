<!-- about section Start-->
<section class="wedding format">
    <div class="container">
        <div class="row">
            <div class="col-md-8 offset-md-2">
                <div class="title">
                    <img src="assets/images/wedding-img/bottom.png" alt=""
                         class="img-fluid title-img">
                    <div class="main-title">
                        <h2 class="gradient-text">Meet Happy Couple</h2>
                    </div>
                    <div class="sub-title">
                        <p>A beautiful app for consectetur adipisicing elit, sed do eiusmod tempor incididunt ut mollit
                            anim id est laborum. Sedut perspiciatis unde omnis.</p>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 offset-xl-2 col-md-6">
                <img src="assets/images/wedding-img/about-us.png" alt="" class="img-fluid about-img">
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="center-text">
                    <div>
                        <div class="format-small-text">
                            <h6># Love Bird</h6>
                        </div>
                        <div class="format-head-text">
                            <h3 class="about-font-header gradient-text">about</h3>
                        </div>
                        <div class="format-sub-text">
                            <p class="about-para">Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum
                                has been the industry's standard dummy text ever since the 1500s.</p>
                            <p class="about-para">When an unknown printer took a galley of type and scrambled it to make
                                a type specimen
                                book. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                                Ipsum has been the industry.</p>
                        </div>
                        <a href="javascript:void(0)" class=" btn btn-default btn-gradient text-white">read more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- about section end-->
