<app-ecommerce-header></app-ecommerce-header>
<!-- breadcrumb section start -->
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Login</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">Login</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>Login</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- section start -->
<section class="login-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <h3>Login</h3>
              <div class="theme-card">
                  <form class="theme-form">
                      <div class="form-group">
                          <label for="email">Email</label>
                          <input type="text" class="form-control" id="email" placeholder="Email" required="">
                      </div>
                      <div class="form-group">
                          <label for="review">Password</label>
                          <input type="password" class="form-control" id="review" placeholder="Enter your password" required="">
                      </div>
                      <a class="btn primary-btn btn-default radius-0" href="#">Login</a>
                  </form>
              </div>
          </div>
          <div class="col-lg-6 right-login">
              <h3>New Customer</h3>
              <div class="theme-card authentication-right">
                  <h6 class="title-font">Create A Account</h6>
                  <p>Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
                  <a class="btn primary-btn btn-default radius-0" [routerLink]="['/shop/signup']">Create an Account</a>
              </div>
          </div>
      </div>
  </div>
</section>
<!--Section ends-->
<app-ecommerce-footer></app-ecommerce-footer>
