<app-wedding-nav></app-wedding-nav>
<app-wedding-header></app-wedding-header>
<app-wedding-about></app-wedding-about>
<app-wedding-banner></app-wedding-banner>
<app-wedding-gallery></app-wedding-gallery>
<app-wedding-countdown></app-wedding-countdown>
<app-wedding-blog></app-wedding-blog>
<app-wedding-when-n-where></app-wedding-when-n-where>
<app-wedding-counter></app-wedding-counter>
<app-wedding-testimonial></app-wedding-testimonial>
<app-wedding-brand></app-wedding-brand>
<app-wedding-subscribe></app-wedding-subscribe>
<app-wedding-footer></app-wedding-footer>